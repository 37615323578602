import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./Auth";
import {CircularProgress} from '@mui/material';
import MDBox from "components/MDBox";

function ProtectedRoute({ children }) {
  const { isAuthenticated, checkAuth } = useAuth();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const verifyAuth = async () => {
      await checkAuth();
      setIsChecking(false);
    };
    verifyAuth();
  }, []);

  if (isChecking) {
    return  <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress size={50} />
            </MDBox>
  }

  if (!isAuthenticated) {
    // If user is not authenticated, redirect to sign-in
    return <Navigate to="/auth/login"/>;
  }

  return children; // If authenticated, render the children components
}

export default ProtectedRoute;