import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Divider,
  Modal,
  Box,
  TextField,
  CircularProgress,
  Chip,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProductSelectionTable from 'components/ProductSelectionTable';
import ThemedCustomStepper from 'components/CustomStepper';
import InlineEditField from 'components/CustonEditableInlineField/CustomEditableInlineField';
import Activities from 'layouts/dashboard/components/Activities';
import Quotations from 'components/Quotations';
import QuotationModal from 'components/CustomModals/AddQuotationModal';

import { Edit, Save, SquareX } from 'lucide-react';

const demoProducts = [
  { id: 471, name: "Laptop", price: 999.99, description: "High-performance laptop" },
  { id: 475, name: "Smartphone", price: 699.99, description: "Latest model smartphone" },
  { id: 472, name: "Tablet", price: 299.99, description: "Lightweight tablet" },
  { id: 473, name: "Desktop Computer", price: 1299.99, description: "Powerful desktop setup" },
  { id: 476, name: "Wireless Headphones", price: 199.99, description: "Noise-cancelling headphones" },
];

function DealDetail() {
  const { id } = useParams();
  const { user, axiosInstance } = useAuth();
  const navigate = useNavigate();
  const [deal, setDeal] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRelated, setDeleteRelated] = useState(false);
  const [activities, setActivities] = useState([]);
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);
  const [isQuotationModalOpen, setIsQuotationModalOpen] = useState(false);
  const [quotations, setQuotations] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [newQuotation, setNewQuotation] = useState({
    deal_id: id,
    total_amount: 0,
    status: 'Sent',
  })
  const [newActivity, setNewActivity] = useState({
    deal_id: id,
    type: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const dealStages = ['Qualification', 'Needs Analysis', 'Proposal', 'Negotiation', 'Closed Won', 'Closed Lost'];
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    fetchDealData();
    fetchActivities();
    fetchQuotations();
    fetchProducts();
  }, [id]);
  

  const fetchDealData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/deals/${id}`);
      setDeal(response.data);
      const fetchedStep = dealStages.indexOf(response.data.stage);
      setActiveStep(fetchedStep >= 0 ? fetchedStep : 0);
    } catch (error) {
      console.error('Error fetching deal:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axiosInstance.get('/inventory/details/');
      console.log(`Products: ${JSON.stringify(response.data)}`);
      
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchActivities = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/deals/${id}/activities`);
      setActivities(response.data || []);
    } catch (error) {
      console.error('Error fetching activities:', error);
      setActivities([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchQuotations = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/deals/${id}/quotations`);
      setQuotations(response.data || []); // Ensure it's always an array
    } catch (error) {
      console.error('Error fetching quotations:', error);
      setQuotations([]); // Set to empty array on error
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductsChange = (products) => {
    setSelectedProducts(products);
  };

  const calculateTotalAmount = () => {
    return selectedProducts.reduce((total, product) => total + (product.price * product.quantity), 0);
  };

  const handleEditToggle = () => {
    if (isEditing) {
      handleSaveChanges();
    }
    setIsEditing(!isEditing);
  };

  const handleFieldUpdate = (field, value) => {
    setUpdatedFields(prev => {
      if (value !== deal[field]) {
        return { ...prev, [field]: value };
      } else {
        const { [field]: _, ...rest } = prev;
        return rest;
      }
    });
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    if (Object.keys(updatedFields).length > 0) {
      try {
        const payload = { ...updatedFields, user_id: user.id };
        const response = await axiosInstance.put(`/deals/${deal.id}`, payload);
        setDeal(response.data);
        const newStep = dealStages.indexOf(response.data.stage);
        setActiveStep(newStep >= 0 ? newStep : 0);
        setUpdatedFields({});
        setSnackbar({
          open: true,
          message: 'Deal updated successfully!',
          severity: 'success',
        });
      } catch (error) {
        console.error('Error updating deal:', error);
        setSnackbar({
          open: true,
          message: 'Error updating deal. Please try again.',
          severity: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }
    setIsEditing(false);
  };

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDelete = async () => {
    setIsLoading(true);
    handleCloseDeleteModal();
    try {
      await axiosInstance.delete(`/deals/${deal.id}`, {
        params: { delete_related: deleteRelated }
      });
      navigate('/deals');
    } catch (error) {
      console.error('Error deleting deal:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenActivityModal = () => {
    setIsActivityModalOpen(true);
  };

  const handleOpenQuotationModal = () => {
    setIsQuotationModalOpen(true);
  };
const handleCloseQuotationModal = () => {
  setIsQuotationModalOpen(false);
  setNewQuotation({
    deal_id: id,
    total_amount: '',
  })
}
  const handleCloseActivityModal = () => {
    setIsActivityModalOpen(false);
    setNewActivity({
      deal_id: id,
      type: '',
      description: '',
      date: new Date().toISOString().split('T')[0],
    });
  };

  const handleActivityInputChange = (e) => {
    setNewActivity({ ...newActivity, [e.target.name]: e.target.value });
  };

  const handleQuotationInputChange = (e) => {
    setNewQuotation({ ...newQuotation, [e.target.name]: e.target.value });
  };

  const handleSaveActivity = async () => {
    setIsLoading(true);
    handleCloseActivityModal();
    try {
      const response = await axiosInstance.post(`/deals/${id}/activities`, newActivity);
      setActivities([...activities, response.data]);
      setSnackbar({
        open: true,
        message: 'Activity created successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error saving activity:', error);
      setSnackbar({
        open: true,
        message: 'Error saving activity',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const handleSaveQuotation = async () => {
  //   const formData = {
  //     deal_id: id,
  //     total_amount: calculateTotalAmount(),
  //     status: quotationStatus,
  //     items: selectedProducts.map(product => ({
  //       product_id: product.id,
  //       quantity: product.quantity,
  //       unit_price: product.price,
  //       total_price: product.price * product.quantity,
  //     })),
  //   };

  //   console.log('New Quotation:', formData);
    
  //   setIsLoading(true);
  //   handleCloseQuotationModal();
  //   try {
  //     const response = await axiosInstance.post('/quotations/', formData);
  //     const QuotationItems = await axiosInstance.post(`/quotations/${response.data.id}/items`, formData.items);
  //     setQuotations(prevQuotations => [...prevQuotations, response.data]);
  //     setSnackbar({
  //       open: true,
  //       message: 'Quotation created successfully!',
  //       severity: 'success',
  //     });
  //   } catch (error) {
  //     console.error('Error saving quotation:', error);
  //     setSnackbar({
  //       open: true,
  //       message: `Error saving quotation: ${error.message}`,
  //       severity: 'error',
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleSaveQuotation = async () => {
  //   const quotationData = {
  //     deal_id: id,
  //     total_amount: calculateTotalAmount(),
  //     status: quotationStatus,
  //   };
  
  //   console.log('New Quotation:', quotationData);
    
  //   setIsLoading(true);
  //   handleCloseQuotationModal();
    
  //   try {
  //     // Create the quotation
  //     const quotationResponse = await axiosInstance.post('/quotations/', quotationData);
  //     const newQuotationId = quotationResponse.data.id;

  //     const itemsData = selectedProducts.map(product => ({
  //       quotation_id: newQuotationId,
  //       product_id: product.id,
  //       quantity: product.quantity,
  //       unit_price: product.price,
  //       total_price: product.price * product.quantity,
  //     }));

  //     // const itemsData = 
  //     // {
  //     //   "quotation_id": 28,
  //     //   "product_id": 475,
  //     //   "quantity": 2,
  //     //   "unit_price": 500,
  //     //   "total_price": 1000
  //     // }
  
  
  //     console.log('Quotation Items:', itemsData);
  //     // Create the quotation items
  //     await axiosInstance.post(`/quotations/${newQuotationId}/items`, itemsData);
  
  //     // Fetch the updated quotation with items
  //     const updatedQuotationResponse = await axiosInstance.get(`/quotations/${newQuotationId}`);
      
  //     setQuotations(prevQuotations => [...prevQuotations, updatedQuotationResponse.data]);
  //     setSnackbar({
  //       open: true,
  //       message: 'Quotation created successfully!',
  //       severity: 'success',
  //     });
  //   } catch (error) {
  //     console.error('Error saving quotation:', error);
  //     setSnackbar({
  //       open: true,
  //       message: `Error saving quotation: ${error.message}`,
  //       severity: 'error',
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleSaveQuotation = async (data) => {
    // const quotationData = {
    //   deal_id: id,
    //   total_amount: calculateTotalAmount(),
    //   status: quotationStatus,
    // };

    const quotationData = {
      deal_id: id,
      total_amount: data.total_amount,
      status: data.status,
      notes: data.notes,
      shipping_method: data.shipping_method,
      payment_methods: data.payment_methods,
      tax: data.tax,
      delivery_date: data.delivery_date || null,
    }
  
    console.log('New Quotation:', quotationData);
    console.log('Quotation Items:', data.items);
    
    
    setIsLoading(true);
    handleCloseQuotationModal();
    
    try {
      
      // Create the quotation
      const quotationResponse = await axiosInstance.post('/quotations/', quotationData);
      const newQuotationId = quotationResponse.data.id;
  
      // Prepare the items data
      // const itemsData = selectedProducts.map(product => ({
      //   product_id: product.id,
      //   quantity: product.quantity,
      //   unit_price: parseFloat(product.price),
      //   total_price: parseFloat((product.price * product.quantity).toFixed(2)),
      //   discount_percentage: product.discount ? parseFloat(product.discount) : null,
      // }));
  
      // Create the quotation items
      await axiosInstance.post(`/quotations/${newQuotationId}/items`, data.items);
  
      // Fetch the updated quotation with items
      const updatedQuotationResponse = await axiosInstance.get(`/quotations/${newQuotationId}`);
      
      setQuotations(prevQuotations => [...prevQuotations, updatedQuotationResponse.data]);
      setSnackbar({
        open: true,
        message: 'Quotation created successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error saving quotation:', error);
      setSnackbar({
        open: true,
        message: `Error saving quotation: ${error.message}`,
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderSection = (title, fields) => (
    <MDBox 
      mb={3} 
      p={3} 
      borderRadius="lg" 
      sx={{ 
        backgroundColor: '#ffffff',
        boxShadow: 3
      }}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">{title}</MDTypography>
      </MDBox>
      <Grid container spacing={3}>
        {fields.map((item) => (
          <Grid item xs={12} md={6} key={item.field}>
            <InlineEditField
              label={item.label}
              value={deal?.[item.field] ?? ''}
              options={item.options ?? null}
              onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
              isEditing={isEditing}
              readOnly={item.readOnly ?? false}
            />
          </Grid>
        ))}
      </Grid>
    </MDBox>
  );
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <MDTypography variant="h6" color="white">
                    Deal Details: {deal?.name}
                    {!isEditing && (
                      <Edit
                        size={20}
                        color="#ffffff"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={handleEditToggle}
                      />
                    )}
                    {isEditing && (
                      <>
                        <Save
                          size={20}
                          color="#ffffff"
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                          onClick={handleSaveChanges}
                        />
                        <SquareX
                          size={20}
                          color="#ffffff"
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                          onClick={() => setIsEditing(false)}
                        />
                      </>
                    )}
                  </MDTypography>
                  <MDBox>
                    <MDButton variant="contained" color="error" onClick={handleOpenDeleteModal}>
                      Delete
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDTypography variant="button" color="white" mb={2}>
                    Stage: {deal?.stage} | Probability: {deal?.probability}%
                  </MDTypography>
                  <ThemedCustomStepper activeStep={activeStep} steps={dealStages} />
                </MDBox>
              </MDBox>
              <MDBox p={3}>
                {!isLoading ? (
                  <>
                    {renderSection("Deal Information", [
                      { label: "Name", field: "name" },
                      { label: "Value", field: "value" },
                      { label: "Currency", field: "currency", options: ["USD", "EUR", "GBP", "INR", "JPY", "RUB", "TRY", "VND", "ZAR"] },
                      { label: "Stage", field: "stage", options: dealStages },
                      { label: "Probability", field: "probability" },
                      { label: "Close Date", field: "close_date" },
                      { label: "Deal Type", field: "deal_type" },
                      { label: "Campaign Source", field: "campaign_source" },
                    ])}

                    {renderSection("Financial Information", [
                      { label: "Expected Revenue", field: "expected_revenue" },
                      { label: "Competitor", field: "competitor" },
                      { label: "Next Step", field: "next_step" },
                    ])}

                    {renderSection("Related Information", [
                      { label: "Account ID", field: "account_id", readOnly: true },
                      { label: "Contact ID", field: "contact_id", readOnly: true },
                      { label: "Lead ID", field: "lead_id", readOnly: true },
                      { label: "Assigned To", field: "user_id", readOnly: true },
                    ])}

                    <Divider sx={{ my: 3 }} />
                    <MDBox>
                      <MDTypography variant="h6" fontWeight="medium">Description</MDTypography>
                      <MDBox mt={2}>
                        <InlineEditField
                          label="Description"
                          value={deal?.description ?? ''}
                          onSave={(newValue) => handleFieldUpdate('description', newValue)}
                          isEditing={isEditing}
                          multiline
                        />
                      </MDBox>
                    </MDBox>

                    <Divider sx={{ my: 3 }} />
                    <MDBox>
                      <MDTypography variant="h6" fontWeight="medium">Tags</MDTypography>
                      <MDBox mt={2}>
                        {deal?.tags && deal.tags.map((tag, index) => (
                          <Chip key={index} label={tag} sx={{ mr: 1, mb: 1 }} />
                        ))}
                      </MDBox>
                    </MDBox>
                  </>
                ) : (
                  <MDBox display="flex" justifyContent="center">
                    <CircularProgress />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>

          
            
          <Grid item xs={12} md={4}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Activities
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={handleOpenActivityModal}>
                  Add Activity
                </MDButton>
              </MDBox>
              <MDBox p={2}>
                <Activities activities={activities.filter(Boolean)} />
              </MDBox>
            </Card>

            <br />
            <br />

            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Quotations
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={handleOpenQuotationModal}>
                  Add Quotation
                </MDButton>
              </MDBox>
              <Quotations quotations={quotations}/>
            </Card>
          </Grid>
         
        </Grid>
      </MDBox>

      {/* Quotation Modal */}
      {/* <Modal
        open={isQuotationModalOpen}
        onClose={handleCloseQuotationModal}
        aria-labelledby="quotation-modal-title"
        aria-describedby="quotation-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="activity-modal-title" variant="h6" component="h2" mb={2}>
            Add New Quotation
          </MDTypography>

          <CustomInputForm
            fields={[
              {label: "Total Amount", name: "total_amount", type: 'number', prefix: '₹'},
              {label: "Status", name: "status", type: "select", options: [
                {value: 'sent', label: 'Sent'},
                {value: 'accepted', label: 'Accepted'},
                {value: 'rejected', label: 'Rejected'},
                {value: 'draft', label: 'Draft'}
              ]},
              {label: "Items", name: "items", type: "array", subfields: [
                {label: "Product", name: "product_id", type: "select", options: demoProducts.map(p => ({value: p.id, label: p.name}))},
                {label: "Quantity", name: "quantity", type: "number"},
                {label: "Unit Price", name: "unit_price", type: "number"},
              ]},
            ]}
            onSubmit={handleSaveQuotation}
            onClose={handleCloseQuotationModal}
          />

          </Box>
      </Modal> */}
      {/* <Modal
        open={isQuotationModalOpen}
        onClose={handleCloseQuotationModal}
        aria-labelledby="quotation-modal-title"
        aria-describedby="quotation-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: 800,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          <MDTypography id="quotation-modal-title" variant="h6" component="h2" mb={2}>
            Add New Quotation
          </MDTypography>

          <ProductSelectionTable 
            products={demoProducts} 
            onProductsChange={handleProductsChange}
          />

          <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
            <MDBox>
              <MDTypography variant="subtitle2" mr={2}>
                Status:
              </MDTypography>
              <Select
                value={quotationStatus}
                onChange={(e) => setQuotationStatus(e.target.value)}
                size="small"
              >
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="sent">Sent</MenuItem>
                <MenuItem value="accepted">Accepted</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
              </Select>
            </MDBox>
            <MDTypography variant="h6">
              Total Amount: ${calculateTotalAmount().toFixed(2)}
            </MDTypography>
          </MDBox>

          <MDBox mt={4} display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseQuotationModal} color="secondary" sx={{ mr: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleSaveQuotation} variant="gradient" color="info">
              Save Quotation
            </MDButton>
          </MDBox>
        </Box>
      </Modal> */}

      <QuotationModal
        isOpen={isQuotationModalOpen}
        onClose={handleCloseQuotationModal}
        onSave={handleSaveQuotation}
        demoProducts={demoProducts}
      />

      {/* Activity Modal */}
      <Modal
        open={isActivityModalOpen}
        onClose={handleCloseActivityModal}
        aria-labelledby="activity-modal-title"
        aria-describedby="activity-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="activity-modal-title" variant="h6" component="h2" mb={2}>
            Add New Activity
          </MDTypography>
          <TextField
            fullWidth
            select
            label="Activity Type"
            name="type"
            value={newActivity.type}
            onChange={handleActivityInputChange}
            margin="normal"
          >
            <option value="Call">Call</option>
            <option value="Meeting">Meeting</option>
            <option value="Email">Email</option>
            <option value="Other">Other</option>
          </TextField>
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={newActivity.description}
            onChange={handleActivityInputChange}
            margin="normal"
            multiline
            rows={4}
          />
          <TextField
            fullWidth
            label="Date"
            name="date"
            type="date"
            value={newActivity.date}
            onChange={handleActivityInputChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseActivityModal} color="secondary" sx={{ marginRight: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleSaveActivity} variant="gradient" color="info">
              Save Activity
            </MDButton>
          </MDBox>
        </Box>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="delete-modal-title" variant="h6" component="h2" mb={2}>
            Confirm Deletion
          </MDTypography>
          <MDTypography id="delete-modal-description" mb={4}>
            Are you sure you want to delete this deal? This action cannot be undone.
          </MDTypography>
          <MDBox display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseDeleteModal} color="secondary" sx={{ marginRight: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleDelete} variant="gradient" color="error">
              Delete
            </MDButton>
          </MDBox>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

    </DashboardLayout>
  );
}

export default DealDetail;