import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Grid,
  Modal,
  Box,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useAuth } from 'auth/Auth';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Icon from "@mui/material/Icon";

// Sample data for the inventory table
const InventoryData = [
  { id: 1, name: "Product A", description: "Description A", quantity: 100, listPrice: 19.99, tax: 2, discount: 0, currency: { name: "US Dollar", symbol: "$" } },
  { id: 2, name: "Product B", description: "Description B", quantity: 50, listPrice: 29.99, tax: 2, discount: 5, currency: { name: "Euro", symbol: "€" } },
  { id: 3, name: "Product C", description: "Description C", quantity: 75, listPrice: 39.99, tax: 2, discount: 10, currency: { name: "British Pound", symbol: "£" } },
  { id: 4, name: "Product D", description: "Description D", quantity: 25, listPrice: 49.99, tax: 2, discount: 0, currency: { name: "Japanese Yen", symbol: "¥" } },
  { id: 5, name: "Product E", description: "Description E", quantity: 60, listPrice: 59.99, tax: 2, discount: 15, currency: { name: "US Dollar", symbol: "$" } },
];

export default function Inventory() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [filters, setFilters] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { user, axiosInstance } = useAuth();
  const [newProduct, setNewProduct] = useState({
    name: '',
    product_code: '',
    description: '',
    category: '',
    price: '',
    quantity: '',
    unit_of_measurement: '',
  });

  useEffect(() => {
    fetchInventory();
  }, []);

  // const fetchInventory = async () => {
  //   try {
  //     const response = await axiosInstance.get('/products');
  //     setInventory(response.data);
  //   } catch (error) {
  //     console.error('Error fetching inventory:', error);
  //   }
  // };

  const fetchInventory = async () => {
    try {
      const response = await axiosInstance.get('/inventory/details');
      setInventory(response.data);
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSaveProduct = async (event) => {
    event.preventDefault();
    try {
      await axiosInstance.post('/products/', newProduct);
      fetchInventory();
      handleCloseModal();
    } catch (error) {
      console.error('Error adding new product:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleRowClick = (rowData) => {
    navigate(`/products/${rowData.product_id}`);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterApply = (column, value) => {
    setFilters({ ...filters, [column]: value });
    handleFilterClose();
  };

  const inventoryTableData = {
    columns: [
      { Header: "ID", accessor: "product_id", width: "5%" },
      { 
        Header: "Name", 
        accessor: "product_name", 
        width: "20%",
        Cell: ({ value, row }) => (
          <MDTypography 
            component="span" 
            onClick={() => handleRowClick(row.original)}
            variant="caption" 
            color="text" 
            fontWeight="medium"
            sx={{ cursor: 'pointer' }}
          >
            {value}
          </MDTypography>
        )
      },
      { Header: "Category", accessor: "category", width: "15%" },
      { Header: "Code", accessor: "product_code", width: "10%" },
      { 
        Header: "Quantity", 
        accessor: "quantity", 
        width: "10%",
        Cell: ({ value }) => (
          <MDTypography
            component="span"
            variant="caption"
            color="text"
            fontWeight="medium"
            sx={{
              backgroundColor: value < 30 ? '#ffcccb' : '#90EE90',
              padding: '4px 8px',
              borderRadius: '4px',
            }}
          >
            {value}
          </MDTypography>
        )
      },
      { 
        Header: "Price", 
        accessor: "price", 
        width: "10%",
        Cell: ({ value }) => (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            ${value.toFixed(2)}
          </MDTypography>
        )
      },
      { Header: "Location", accessor: "location", width: "15%" },
      { 
        Header: "Last Updated", 
        accessor: "updated_at", 
        width: "15%",
        Cell: ({ value }) => new Date(value).toLocaleDateString()
      },
    ],
    rows: inventory,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="inventory_2"
                title="Total Products"
                count={inventory.length}
                percentage={{
                  color: "success",
                  amount: "+5%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="store"
                title="Low Stock Items"
                count={inventory.filter(product => product.quantity < 30).length}
                percentage={{
                  color: "success",
                  amount: "-3%",
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="attach_money"
                title="Total Value"
                count={`$${inventory.reduce((sum, product) => sum + product.quantity * product.listPrice, 0).toFixed(2)}`}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="category"
                title="Product Categories"
                count="15"
                percentage={{
                  color: "success",
                  amount: "+10%",
                  label: "than last quarter",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Inventory
                  </MDTypography>
                  <MDButton 
                    variant="gradient" 
                    color="dark" 
                    onClick={handleOpenModal}
                    title="Click to add a new product to the inventory"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Add New Product
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={inventoryTableData}
                    isSorted={true}
                    noEndBorder
                    canSearch
                    searchPlaceholder="Search for products..."
                    entriesPerPage={{
                      defaultValue: 10,
                      entries: [5, 10, 15, 20, 25],
                    }}
                    showTotalEntries
                    pagination={{ variant: "gradient", color: "info" }}
                    exportCSV={true}

                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />

      {/* Modal for adding new product */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography variant="h6" component="h2" mb={2}>
            Add New Product
          </MDTypography>
          <form onSubmit={handleSaveProduct}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField fullWidth label="Name" name="name" value={newProduct.name} onChange={handleInputChange} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Product Code" name="product_code" value={newProduct.product_code} onChange={handleInputChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Description" name="description" value={newProduct.description} onChange={handleInputChange} multiline rows={2} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Category" name="category" value={newProduct.category} onChange={handleInputChange} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Price" name="price" type="number" value={newProduct.price} onChange={handleInputChange} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Quantity" name="quantity" type="number" value={newProduct.quantity} onChange={handleInputChange} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Unit of Measurement" name="unit_of_measurement" value={newProduct.unit_of_measurement} onChange={handleInputChange} />
              </Grid>
            </Grid>
            <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
              <MDButton onClick={handleCloseModal} color="secondary" sx={{ marginRight: 2 }}>
                Close
              </MDButton>
              <MDButton type="submit" variant="gradient" color="info">
                Save Product
              </MDButton>
            </MDBox>
          </form>
        </Box>
      </Modal>

    </DashboardLayout>
  );
}
