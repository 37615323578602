import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Search, Plus, Minus } from 'lucide-react';

const ProductSelectionTable = ({ products, onProductsChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddProduct = (product) => {
    const existingProduct = selectedProducts.find(p => p.id === product.id);
    if (existingProduct) {
      handleQuantityChange(product.id, existingProduct.quantity + 1);
    } else {
      setSelectedProducts([...selectedProducts, { ...product, quantity: 1, discount: 0 }]);
    }
  };

  const handleQuantityChange = (productId, newQuantity) => {
    if (newQuantity < 0) return;
    const updatedProducts = selectedProducts.map(product =>
      product.id === productId ? { ...product, quantity: newQuantity } : product
    );
    setSelectedProducts(updatedProducts.filter(product => product.quantity > 0));
  };

  const handleDiscountChange = (productId, newDiscount) => {
    const updatedProducts = selectedProducts.map(product =>
      product.id === productId ? { ...product, discount: parseFloat(newDiscount) || 0 } : product
    );
    setSelectedProducts(updatedProducts);
  };

  const calculateNetPrice = (product) => {
    const totalPrice = product.price * product.quantity;
    const discountAmount = totalPrice * (product.discount / 100);
    return totalPrice - discountAmount;
  };

  useEffect(() => {
    onProductsChange(selectedProducts);
  }, [selectedProducts, onProductsChange]);

  return (
    <MDBox>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search products..."
        value={searchTerm}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />
      <TableContainer component={Paper} sx={{ maxHeight: 300, overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts.map((product) => (
              <TableRow key={product.id} hover>
                <TableCell>{product.name}</TableCell>
                <TableCell align="right">${product.price.toFixed(2)}</TableCell>
                <TableCell align="right">
                  <MDButton 
                    variant="outlined" 
                    color="info" 
                    size="small"
                    onClick={() => handleAddProduct(product)}
                  >
                    Add
                  </MDButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MDTypography variant="h6" mt={4} mb={2}>Selected Products</MDTypography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="right">Gross Price</TableCell>
              <TableCell align="right">Discount (%)</TableCell>
              <TableCell align="right">Net Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedProducts.map((product) => (
              <TableRow key={product.id}>
                <TableCell>{product.name}</TableCell>
                <TableCell align="right">
                  <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                    <IconButton 
                      size="small" 
                      onClick={() => handleQuantityChange(product.id, product.quantity - 1)}
                    >
                      <Minus size={16} />
                    </IconButton>
                    <MDTypography mx={1}>{product.quantity}</MDTypography>
                    <IconButton 
                      size="small" 
                      onClick={() => handleQuantityChange(product.id, product.quantity + 1)}
                    >
                      <Plus size={16} />
                    </IconButton>
                  </MDBox>
                </TableCell>
                <TableCell align="right">${product.price.toFixed(2)}</TableCell>
                <TableCell align="right">${(product.price * product.quantity).toFixed(2)}</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={product.discount}
                    onChange={(e) => handleDiscountChange(product.id, e.target.value)}
                    InputProps={{ 
                      inputProps: { min: 0, max: 100 },
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    
                    style={{ width: 100 }}
                  />
                </TableCell>
                <TableCell align="right">${calculateNetPrice(product).toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MDBox>
  );
};

export default ProductSelectionTable;