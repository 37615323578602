import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Icon,
  IconButton,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { FaFileCsv } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";
import { handleExportCSV } from 'components/ExportUtils';

function Quotation() {
  const demoQuotations = [
    {
      id: 1,
      customer: "Acme Corp",
      amount: "$5,000.00",
      status: "Sent",
      date: "2024-09-01",
    },
    {
      id: 2,
      customer: "TechStart Inc",
      amount: "$12,350.00",
      status: "Draft",
      date: "2024-08-28",
    },
    {
      id: 3,
      customer: "Global Innovations",
      amount: "$8,750.00",
      status: "Accepted",
      date: "2024-08-25",
    },
    {
      id: 4,
      customer: "Smith Enterprises",
      amount: "$3,200.00",
      status: "Rejected",
      date: "2024-08-20",
    },
    {
      id: 5,
      customer: "Horizon Ltd",
      amount: "$15,000.00",
      status: "Sent",
      date: "2024-08-15",
    },
    {
      id: 6,
      customer: "Stellar Systems",
      amount: "$7,500.00",
      status: "Draft",
      date: "2024-08-10",
    },
    {
      id: 7,
      customer: "Quantum Solutions",
      amount: "$22,000.00",
      status: "Accepted",
      date: "2024-08-05",
    },
    {
      id: 8,
      customer: "Phoenix Industries",
      amount: "$9,800.00",
      status: "Sent",
      date: "2024-07-30",
    },
    {
      id: 9,
      customer: "Sapphire Tech",
      amount: "$6,400.00",
      status: "Draft",
      date: "2024-07-25",
    },
    {
      id: 10,
      customer: "Nova Dynamics",
      amount: "$18,500.00",
      status: "Sent",
      date: "2024-07-20",
    },
  ];

  const [quotations, setQuotations] = useState([]);
  const navigate = useNavigate();
  const { axiosInstance } = useAuth();
  const theme = useTheme();

  const { id } = useParams();


  const fetchQuotations = async () => {
    try {
      // setQuotations(demoQuotations); // TO BE REMOVED

      const response = await axiosInstance.get(`/quotations/`);
      console.log(`Quotations: ${JSON.stringify(response.data)}`);
      
      setQuotations(response.data);

    } catch (error) {
      console.error('Error fetching quotations data:', error);
    }
  };
  
  useEffect(() => {
    fetchQuotations();
  }, []);

  const handleSendEmail = (id) => {
    console.log(`Sending email for quotation ${id}`);
    // Implement email sending logic here
  };

  const handleDownloadPDF = (id) => {
    console.log(`Downloading PDF for quotation ${id}`);
    // Implement PDF download logic here
  };

  const handleDownloadCSV = () => {
    handleExportCSV();
    console.log(`Downloading CSV for quotation ${id}`);
  }

 const handleRowClick = (rowData) => {
  navigate(`/quote/detail/${rowData.id}`);
};

  const handleCreateNewQuotation = () => {
    navigate('/quotation/new');
  };

  const quotationTableData = {
    columns: [
      { 
        Header: "Customer", 
        accessor: "account_name", 
        width: "25%",
        Cell: ({ value, row }) => (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            sx={{ cursor: 'pointer' }}
            onClick={() => handleRowClick(row.original)}
          >
            {value}
          </MDTypography>
        )
      },
      { Header: "Amount", accessor: "total_amount", width: "20%" },
      { 
        Header: "Status", 
        accessor: "status", 
        width: "20%",
        Cell: ({ value }) => {
        
          let color;
          switch(value.toLowerCase()) {
            case 'sent':
              color = 'info';
              break;
            case 'draft':
              color = 'warning';
              break;
            case 'accepted':
              color = 'success';
              break;
            case 'rejected':
              color = 'error';
              break;
            default:
              color = 'default';
          }
          return (
            <MDTypography
              component="span"
              variant="caption"
              color="white"
              fontWeight="medium"
              sx={{
                backgroundColor: (theme) => theme.palette[color].main,
                padding: '4px 8px',
                borderRadius: '4px',
              }}
            >
              {value}
            </MDTypography>
          );
        }
      },
      { Header: "Date", accessor: "created_at", width: "20%" },
      {
        Header: "Actions",
        accessor: "actions",
        width: "15%",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="space-around">
            <Tooltip title="Send Email">
              <IconButton onClick={() => handleSendEmail(row.original.id)} color="info" size="medium">
                <Icon>email</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Download PDF">
              <IconButton onClick={() => handleDownloadPDF(row.original.id)} color="info" size="medium">
                <Icon>picture_as_pdf</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Download CSV">
            <IconButton >
              <FaFileCsv style={{ color: theme.palette.info.main}} size={23} />
            </IconButton>
          </Tooltip>
          </MDBox>
        ),
      },
    ],
    rows: quotations,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <MDTypography variant="h6" color="white">
                    Recent Quotations
                  </MDTypography>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={handleCreateNewQuotation}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;New Quotation
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={quotationTableData}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch
                  searchPlaceholder="Search for quotations..."
                  pagination={{ variant: "gradient", color: "info" }}
                  exportCSV={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Quotation;