import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function InlineEditField({ 
    label, 
    value, 
    onSave, 
    readOnly = false, 
    multiline = false, 
    options = null, 
    isEditing 
}) {
    
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const validateField = (label, value) => {
    switch (label) {
      case 'email':
        return /\S+@\S+\.\S+/.test(value); // Simple email validation
      case 'phone':
        return /^\d{10}$/.test(value); // Simple phone number validation
      case 'postal_code':
        return /^\d{5}$/.test(value); // Simple postal code validation
      case 'annual_revenue':
        return !isNaN(parseFloat(value)) && isFinite(value); // Number validation
      // Add more cases as needed
      default:
        return true; // For fields without specific validation
    }
  };

  const handleBlur = () => {
    if (inputValue !== value) {
      onSave(inputValue);
    }
  };

  if (isEditing && !readOnly) {
    if (options) {
      return (
        <FormControl fullWidth>
          <InputLabel id="inline-edit">{label}</InputLabel>
          <Select
            labelId="inline-edit"
            id="inline-edit"
            value={inputValue}
            onChange={handleChange}
            onBlur={handleBlur}
            label={label}
            inputProps={{ IconComponent: () => <ArrowDropDownIcon style={{ scale: '1.5' }} /> }} // Add dropdown icon
            style={{
              padding: '11px', // Add padding for better height
            }}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <TextField
          fullWidth
          label={label}
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          multiline={multiline}
          rows={multiline ? 4 : 1}
        />
      );
    }
  } else {
    return (
      <MDTypography variant="button" fontWeight="regular" color="text">
        <strong>{label}:</strong> {value || `No ${label.toLowerCase()}`}
      </MDTypography>   
    );
  }
}

export default InlineEditField