import React, { useState, useEffect } from 'react';
import {
  Card,
  Grid,
  TextField,
  Box,
  IconButton,
  Tooltip,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Icon from "@mui/material/Icon";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Mock data for users
const mockUsers = [
  { id: 1, name: "Alice Johnson", email: "alice@acme.com", role: "Admin", status: "Active" },
  { id: 2, name: "Bob Smith", email: "bob@acme.com", role: "User", status: "Active" },
  { id: 3, name: "Charlie Brown", email: "charlie@acme.com", role: "User", status: "Inactive" },
];

function UserManagement() {
  const [users, setUsers] = useState(mockUsers);
  const [filteredUsers, setFilteredUsers] = useState(mockUsers);
  const [newUser, setNewUser] = useState({ name: '', email: '', role: '', status: 'Active' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const filtered = users.filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.role.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [users, searchTerm]);

  const validateForm = () => {
    const newErrors = {};
    if (!newUser.name) newErrors.name = "Name is required";
    if (!newUser.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(newUser.email)) newErrors.email = "Email is invalid";
    if (!newUser.role) newErrors.role = "Role is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddUser = () => {
    if (validateForm()) {
      setUsers([...users, { ...newUser, id: users.length + 1 }]);
      setNewUser({ name: '', email: '', role: '', status: 'Active' });
      setIsModalOpen(false);
    }
  };

  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleResetPassword = (userId) => {
    console.log(`Reset password for user ${userId}`);
    // Implement password reset logic here
  };

  const handleToggleStatus = (userId) => {
    setUsers(users.map(user => {
      if (user.id === userId) {
        return { ...user, status: user.status === 'Active' ? 'Inactive' : 'Active' };
      }
      return user;
    }));
  };

  const usersTableData = {
    columns: [
      { Header: "Name", accessor: "name", width: "20%" },
      { Header: "Email", accessor: "email", width: "25%" },
      { Header: "Role", accessor: "role", width: "15%" },
      { Header: "Status", accessor: "status", width: "15%" },
      {
        Header: "Actions",
        accessor: "actions",
        width: "25%",
        Cell: ({ row }) => (
          <Box>
            <Tooltip title="Reset Password">
              <IconButton onClick={() => handleResetPassword(row.original.id)} color="info">
                <Icon>lock_reset</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={row.original.status === 'Active' ? 'Deactivate' : 'Activate'}>
              <IconButton onClick={() => handleToggleStatus(row.original.id)} color={row.original.status === 'Active' ? 'error' : 'success'}>
                <Icon>{row.original.status === 'Active' ? 'person_off' : 'person'}</Icon>
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    rows: filteredUsers,
  };

  // Calculate statistics
  const totalAdmins = users.filter(user => user.role === 'Admin').length;
  const totalUsers = users.length;
  const activeUsers = users.filter(user => user.status === 'Active').length;
  const percentageLoggedIn = Math.round((activeUsers / totalUsers) * 100);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <ComplexStatisticsCard
              color="primary"
              icon="admin_panel_settings"
              title="Total Admins"
              count={totalAdmins}
              percentage={{
                color: "success",
                amount: "+1",
                label: "than last week",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ComplexStatisticsCard
              icon="group"
              title="Total Users"
              count={totalUsers}
              percentage={{
                color: "success",
                amount: "+3",
                label: "than last month",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ComplexStatisticsCard
              color="success"
              icon="person"
              title="Users Logged In Today"
              count={`${percentageLoggedIn}%`}
              percentage={{
                color: "success",
                amount: "",
                label: "of total users",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  User Management
                </MDTypography>
                <MDButton variant="contained" color="white" onClick={() => setIsModalOpen(true)}>
                  Add New User
                </MDButton>
              </MDBox>
              <MDBox p={3}>
                <MDBox mb={3}>
                  <TextField
                    fullWidth
                    label="Search Users"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </MDBox>
                <DataTable
                  table={usersTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      {/* Add User Modal */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="add-user-modal"
        aria-describedby="modal-to-add-new-user"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <MDTypography variant="h6" component="h2" gutterBottom>
            Add New User
          </MDTypography>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={newUser.name}
            onChange={handleInputChange}
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={newUser.email}
            onChange={handleInputChange}
            margin="normal"
            error={!!errors.email}
            helperText={errors.email}
          />
          <FormControl fullWidth margin="normal" error={!!errors.role}>
            <InputLabel>Role</InputLabel>
            <Select
              name="role"
              value={newUser.role}
              onChange={handleInputChange}
              label="Role"
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="User">User</MenuItem>
            </Select>
            {errors.role && <MDTypography variant="caption" color="error">{errors.role}</MDTypography>}
          </FormControl>
          <MDBox mt={3} display="flex" justifyContent="flex-end">
            <MDButton onClick={() => setIsModalOpen(false)} color="secondary" sx={{ mr: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleAddUser} variant="contained" color="info">
              Add User
            </MDButton>
          </MDBox>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default UserManagement;
