import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Divider,
  Modal,
  Box,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import InlineEditField from 'components/CustonEditableInlineField/CustomEditableInlineField';
import { Edit, Save } from 'lucide-react';
import Contacts from 'layouts/dashboard/components/Contacts';
import { options } from '@fullcalendar/core/preact';
import AddContactModal from 'components/AddContactModal';

function AccountDetail() {
  const { user, axiosInstance } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const [account, setAccount] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  
  useEffect(() => {
    fetchAccountData();
    fetchContacts();
  }, [id]);

  console.log(`Account ID: ${id}`);


  const fetchAccountData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/accounts/${id}`);
      setAccount(response.data);
      console.log(`response.data for fetchAccountData:`, response.data);
      
    } catch (error) {
      console.error('Error fetching account data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchContacts = async () => {
    try {
      const response = await axiosInstance.get(`/accounts/${id}/contacts`);
      const contactNames = response.data.map(contact => 
        `${contact.first_name} ${contact.last_name}`.trim()
      );
      setContacts(contactNames);
      console.log('Processed contact names:', contactNames);
      console.log(`response.data for  fetchContacts:`, response.data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const handleEditToggle = () => {
    if (isEditing) {
      handleSaveChanges();
    }
    setIsEditing(!isEditing);
  };

  const handleFieldUpdate = (field, value) => {
    // setIsLoading(true);
    setUpdatedFields(prev => {
      if (value !== account[field]) {
        return { ...prev, [field]: value };
      } else {
        const { [field]: _, ...rest } = prev;
        return rest;
      }
    });
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    console.log('Saving changes:', updatedFields);
    if (Object.keys(updatedFields).length > 0) {
      try {
        const response = await axiosInstance.put(`/accounts/${account.id}`, updatedFields);
        await fetchAccountData();
      } catch (error) {
        console.error('Error updating account:', error);
      } finally {
        setIsLoading(false);
        setIsEditing(false);
        setUpdatedFields({});
      }
    }
  };

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/accounts/${account.id}`);
      navigate('/accounts');
    } catch (error) {
      console.error('Error deleting account:', error);
    }
    handleCloseDeleteModal();
  };

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };

  const handleAddContact = () => {
    try {
      console.log('Adding contact...');
      handleCloseContactModal();
      const response = axiosInstance.post(`/contacts/`, {
        first_name: 'John',
        last_name: 'Doe',
        email: 'jHqCp@example.com',
      })
      

      
      
    } catch (error) {
      
    }
  };

  const renderSection = (title, fields) => (
    <MDBox 
      mb={3} 
      p={3} 
      borderRadius="lg" 
      sx={{ 
        backgroundColor: 'white',
        boxShadow: 3
      }}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">{title}</MDTypography>
      </MDBox>
      <Grid container spacing={3}>
        {fields.map((item) => (
          <Grid item xs={12} md={6} key={item.field}>
            <InlineEditField
              label={item.label}
              value={account?.[item.field] ?? ''}
              options={item.options ?? null}
              onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
              isEditing={isEditing}
              readOnly={item.readOnly ?? false}
            />
          </Grid>
        ))}
      </Grid>
    </MDBox>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Account Details: {account?.name}
                    {!isEditing && (
                      <Edit
                        size={20}
                        color="#FFFFFF"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={handleEditToggle}
                      />
                    )}
                    {isEditing && (
                      <Save
                        size={20}
                        color="#FFFFFF"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={handleSaveChanges}
                      />
                    )}
                  </MDTypography>
                  <MDButton variant="contained" color="error" onClick={handleOpenDeleteModal}>
                    Delete
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox p={3}>
                {!isLoading ? (
                  <>
                    {renderSection("Basic Information", [
                      { label: "Account ID", field: "id", readOnly: true },
                      { label: "Name", field: "name" },
                      { label: "Account Type", field: "account_type", options: ["Customer", "Prospect", "Partner"] },
                      { label: "Industry", 
                        field: "industry", 
                        options: [
                          "Automotive", 
                          "Banking", 
                          "Construction", 
                          "Education", 
                          "Financial Services", 
                          "Government", 
                          "Healthcare", 
                          "Manufacturing", 
                          "Media", 
                          "Retail", 
                          "Technology", 
                          "Travel", 
                          "Other"]},
                      { label: "Account Status", field: "account_status", options: ["Active", "Inactive", "Processing"] },
                      { label: "Website", field: "website" },
                      { label: "Phone", field: "phone" },
                      { label: "Email", field: "email" },
                    ])}

                    {renderSection("Financial Information", [
                      { label: "Annual Revenue", field: "annual_revenue" },
                      { label: "Number of Employees", field: "number_of_employees" },
                    ])}

                    {renderSection("Billing Address", [
                      { label: "Address", field: "billing_address" },
                      { label: "City", field: "billing_city" },
                      { label: "State", field: "billing_state" },
                      { label: "Country", field: "billing_country" },
                      { label: "Postal Code", field: "billing_postal_code" },
                    ])}

                    {renderSection("Shipping Address", [
                      { label: "Address", field: "shipping_address" },
                      { label: "City", field: "shipping_city" },
                      { label: "State", field: "shipping_state" },
                      { label: "Country", field: "shipping_country" },
                      { label: "Postal Code", field: "shipping_postal_code" },
                    ])}

                    {renderSection("Additional Information", [
                      { label: "Manager Name", field: "manager_name" },
                      { label: "Manager ID", field: "manager_id" },
                      { label: "Tags", field: "tags" },
                      { label: "Updated At", field: "updated_at", readOnly: true },
                      { label: "Created At", field: "created_at", readOnly: true },
                      
                      
                    ])}

                    {renderSection("Description", [
                      { label: "Notes", field: "notes" },
                      { label: "Attachments", field: "attachments", readOnly: true },
                    ])}
                  </>
                ) : (
                  <MDBox display="flex" justifyContent="center">
                    <CircularProgress />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Contacts
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={handleOpenContactModal}>
                  Add Contact
                </MDButton>
              </MDBox>
              <MDBox p={2}>
                <Contacts contacts={contacts} />
              </MDBox>
            </Card>
          </Grid>

        </Grid>
      </MDBox>

      {/* Add Contact Modal */}
      {/* <Modal
        open={isContactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="contact-modal-title"
        aria-describedby="contact-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="contact-modal-title" variant="h6" component="h2" mb={2} onClick={handleOpenContactModal}>
            Add Contact
          </MDTypography>
          <MDTypography id="contact-modal-description" mb={4}>
            Add a new contact for this account.
          </MDTypography>
            <CustomInputForm
              fields={fields}
              onSubmit={handleSubmit}
              onCancel={onClose}
            />
          <MDBox display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseContactModal} color="secondary" sx={{ marginRight: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleAddContact} variant="gradient" color="info">
              Add Contact
            </MDButton>
          </MDBox>
        </Box>
      </Modal> */}

      <AddContactModal
        isOpen={isContactModalOpen}
        onClose={handleCloseContactModal}
        accountId={id}
        onContactAdded={fetchContacts}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="delete-modal-title" variant="h6" component="h2" mb={2}>
            Confirm Deletion
          </MDTypography>
          <MDTypography id="delete-modal-description" mb={4}>
            Are you sure you want to delete this account? This action cannot be undone.
          </MDTypography>
          <MDBox display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseDeleteModal} color="secondary" sx={{ marginRight: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleDelete} variant="contained" color="error">
              Delete
            </MDButton>
          </MDBox>
        </Box>
      </Modal>

      <Footer />
    </DashboardLayout>
  );
}

export default AccountDetail;