import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const TableSkeleton = () => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell><Skeleton variant="text" width="80%" /></TableCell>
        <TableCell><Skeleton variant="text" width="60%" /></TableCell>
        <TableCell><Skeleton variant="text" width="40%" /></TableCell>
        <TableCell><Skeleton variant="text" width="50%" /></TableCell>
        <TableCell><Skeleton variant="text" width="70%" /></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {[...Array(5)].map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          <TableCell><Skeleton variant="text" width="80%" /></TableCell>
          <TableCell><Skeleton variant="text" width="60%" /></TableCell>
          <TableCell><Skeleton variant="text" width="40%" /></TableCell>
          <TableCell><Skeleton variant="text" width="50%" /></TableCell>
          <TableCell><Skeleton variant="text" width="70%" /></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default TableSkeleton;