import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Calendar from "examples/Calendar";

// Data
import calendarEventsData from "layouts/tasks/data/calendarEventsData";

const colorOptions = [
  { value: "info", label: "Blue" },
  { value: "success", label: "Green" },
  { value: "warning", label: "Yellow" },
  { value: "error", label: "Red" },
  { value: "primary", label: "Purple" },
];

function Tasks() {
  const [events, setEvents] = useState(calendarEventsData);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewEvent, setIsNewEvent] = useState(false);
  const [sharedEvents, setSharedEvents] = useState([]);
  const [userPermissions, setUserPermissions] = useState({});

  const handleEventClick = (info) => {
    setSelectedEvent({
      ...info.event.toPlainObject(),
      color: info.event.classNames[0] || "info",
    });
    setIsNewEvent(false);
    setIsModalOpen(true);
  };

  const handleDateClick = (info) => {
    const newEvent = {
      title: "",
      start: info.dateStr,
      end: info.dateStr,
      allDay: info.allDay,
      type: "Event",
      color: "info",
    };
    setSelectedEvent(newEvent);
    setIsNewEvent(true);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    setIsNewEvent(false);
  };

  const handleSaveEvent = () => {
    const eventToSave = {
      ...selectedEvent,
      className: selectedEvent.color,
    };

    if (isNewEvent) {
      // Add new event
      const newEvent = { ...eventToSave, id: Date.now() };
      setEvents([...events, newEvent]);

      // Share the new event if necessary
      if (selectedEvent.sharedWith) {
        selectedEvent.sharedWith.forEach((share) => {
          shareEvent(newEvent.id, share.email, share.permission);
        });
      }
    } else {
      // Update existing event
      const updatedEvents = events.map((event) =>
        event.id === selectedEvent.id ? eventToSave : event
      );
      setEvents(updatedEvents);

      // Update shared events
      setSharedEvents((prev) =>
        prev.map((share) =>
          share.eventId === selectedEvent.id
            ? { ...share, ...eventToSave }
            : share
        )
      );
    }
    handleCloseModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedEvent((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedEvent((prev) => ({ ...prev, [name]: checked }));
  };

  const shareEvent = (eventId, userEmail, permission) => {
    setSharedEvents((prev) => [
      ...prev,
      { eventId, sharedWithEmail: userEmail, permission },
    ]);
    setUserPermissions((prev) => ({
      ...prev,
      [eventId]: { ...prev[eventId], [userEmail]: permission },
    }));
  };

  const updatePermission = (eventId, userEmail, newPermission) => {
    setUserPermissions((prev) => ({
      ...prev,
      [eventId]: { ...prev[eventId], [userEmail]: newPermission },
    }));
  };

  const revokeAccess = (eventId, userEmail) => {
    setSharedEvents((prev) =>
      prev.filter((share) => !(share.eventId === eventId && share.sharedWithEmail === userEmail))
    );
    setUserPermissions((prev) => {
      const updatedPermissions = { ...prev[eventId] };
      delete updatedPermissions[userEmail];
      return { ...prev, [eventId]: updatedPermissions };
    });
  };

  useEffect(() => {
    // Fetch shared events from the server
    // This is a placeholder for the actual API call
    const fetchSharedEvents = async () => {
      // const response = await fetch('/api/shared-events');
      // const data = await response.json();
      // setSharedEvents(data.sharedEvents);
      // setUserPermissions(data.userPermissions);
    };

    fetchSharedEvents();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <MDBox mb={3}>
              <Calendar
                header={{ title: "Calendar" }}
                events={[...events, ...sharedEvents.filter((share) => userPermissions[share.eventId]?.[share.sharedWithEmail] >= 'view')]}
                eventClick={handleEventClick}
                dateClick={handleDateClick}
                height="calc(100vh - 310px)"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h6" fontWeight="medium" mb={2}>
                  Upcoming Events
                </MDTypography>
                <MDBox>
                  {events
                    .filter((event) => new Date(event.start) > new Date())
                    .sort((a, b) => new Date(a.start) - new Date(b.start))
                    .slice(0, 5)
                    .map((event, index) => (
                      <MDBox
                        key={index}
                        mb={2}
                        display="flex"
                        alignItems="center"
                        sx={{
                          cursor: "pointer",
                          backgroundColor: event.className,
                          borderRadius: "8px",
                          padding: "8px",
                        }}
                        onClick={() => handleEventClick({ event })}
                      >
                        <Icon
                          fontSize="small"
                          color="primary"
                          sx={{ marginRight: 1 }}
                        >
                          {event.allDay ? "event" : "schedule"}
                        </Icon>
                        <MDBox>
                          <MDTypography variant="body2" fontWeight="medium">
                            {event.title}
                          </MDTypography>
                          <MDTypography variant="caption" color="text">
                            {new Date(event.start).toLocaleDateString()} 
                            {!event.allDay && ` ${new Date(event.start).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '16px',
        }}>
          <MDTypography variant="h6" component="h2" mb={2}>
            {isNewEvent ? "Create New Event" : "Edit Event"}
          </MDTypography>
          {selectedEvent && (
            <Box component="form" noValidate autoComplete="off">
              <TextField
                fullWidth
                margin="normal"
                name="title"
                label="Event Title"
                value={selectedEvent.title}
                onChange={handleInputChange}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel id="event-type-label">Event Type</InputLabel>
                <Select
                  labelId="event-type-label"
                  name="type"
                  value={selectedEvent.type}
                  onChange={handleInputChange}
                  label="Event Type"
                >
                  <MenuItem value="Event">Event</MenuItem>
                  <MenuItem value="Meeting">Meeting</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                margin="normal"
                name="start"
                label="Start Date"
                type="datetime-local"
                value={selectedEvent.start}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                name="end"
                label="End Date"
                type="datetime-local"
                value={selectedEvent.end}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedEvent.allDay}
                    onChange={handleCheckboxChange}
                    name="allDay"
                  />
                }
                label="All Day Event"
              />
              <FormControl component="fieldset" margin="normal">
                <MDTypography variant="subtitle2" gutterBottom>
                  Color Label
                </MDTypography>
                <RadioGroup
                  aria-label="color"
                  name="color"
                  value={selectedEvent.color}
                  onChange={handleInputChange}
                  row
                >
                  {colorOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      sx={{
                        '& .MuiRadio-root': {
                          color: `${option.value}.main`,
                          '&.Mui-checked': {
                            color: `${option.value}.main`,
                          },
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Box mt={2}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleSaveEvent}
                  sx={{ color: '#ffffff' }}
                >
                  {isNewEvent ? "Create Event" : "Save Changes"}
                </Button>
                <Button 
                  variant="outlined" 
                  onClick={handleCloseModal} 
                  sx={{ ml: 2, color: 'primary.main' }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default Tasks;