import React, { useState } from 'react';
import {
  Card,
  Grid,
  TextField,
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { Link } from 'react-router-dom';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Mock data (you can replace this with actual data fetching logic)
const mockCompanyDetails = {
  name: "Acme Corporation",
  contactName: "John Doe",
  email: "john.doe@acme.com",
  phone: "+1 123-456-7890",
  billingAddress: "123 Business St, City, Country, 12345",
  taxNumber: "TAX123456789",
  country: "United States",
  defaultCurrency: "USD",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Settings() {
  const [value, setValue] = useState(0);
  const [companyDetails, setCompanyDetails] = useState(mockCompanyDetails);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCompanyDetailChange = (e) => {
    setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Settings
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
                  <Tab label="General" />
                  <Tab label="Users" />
                  <Tab label="Email" />
                  <Tab label="AI" />
                  <Tab label="Personal Info" />
                  <Tab label="Automations" />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Company Name"
                        name="name"
                        value={companyDetails.name}
                        onChange={handleCompanyDetailChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Country"
                        name="country"
                        value={companyDetails.country}
                        onChange={handleCompanyDetailChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Default Currency"
                        name="defaultCurrency"
                        value={companyDetails.defaultCurrency}
                        onChange={handleCompanyDetailChange}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Tax Number"
                        name="taxNumber"
                        value={companyDetails.taxNumber}
                        onChange={handleCompanyDetailChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Billing Address"
                        name="billingAddress"
                        value={companyDetails.billingAddress}
                        onChange={handleCompanyDetailChange}
                        margin="normal"
                        multiline
                        rows={4}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography variant="h6" gutterBottom>
                      User Management
                    </MDTypography>
                    <MDButton
                      component={Link}
                      to="/user-management"
                      variant="contained"
                      color="info"
                    >
                      Manage Users
                    </MDButton>
                  </MDBox>
                  {/* Add user list or management options here */}
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <MDTypography variant="h6" gutterBottom>
                    Email Configuration
                  </MDTypography>
                  <MDBox mt={2}>
                    <Button
                      component={Link}
                      to="/email-setup"
                      variant="contained"
                      color="primary"
                    >
                      Setup Email Connection
                    </Button>
                  </MDBox>
                  {/* Add email configuration options here */}
                </TabPanel>

                <TabPanel value={value} index={3}>
                  <MDTypography variant="h6" gutterBottom>
                    AI Settings
                  </MDTypography>
                  {/* Add AI-related settings here */}
                </TabPanel>

                <TabPanel value={value} index={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Contact Name"
                        name="contactName"
                        value={companyDetails.contactName}
                        onChange={handleCompanyDetailChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        value={companyDetails.email}
                        onChange={handleCompanyDetailChange}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Phone"
                        name="phone"
                        value={companyDetails.phone}
                        onChange={handleCompanyDetailChange}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={5}>
                  <MDTypography variant="h6" gutterBottom>
                    Automation Settings
                  </MDTypography>
                  {/* Add automation-related settings here */}
                </TabPanel>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Settings;