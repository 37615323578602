import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import colors from "assets/theme/base/colors";
import InlineEditField from 'components/CustonEditableInlineField/CustomEditableInlineField';

function OrderDetail() {
  const { axiosInstance } = useAuth();
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const orderSteps = ['Order Placed', 'Processing', 'Shipped', 'Out For Delivery', 'Delivered'];

  useEffect(() => {
    fetchOrderData();
  }, [id]);

  const fetchOrderData = async () => {
    setIsLoading(true);
    try {
      // In a real application, you would fetch the order data from your API
      // For now, we'll use mock data
      const mockOrderData = {
        id: id,
        customerName: "John Doe",
        orderDate: "2023-08-29",
        status: "Processing",
        total: 299.99,
        items: [
          { name: "Product A", quantity: 2, price: 99.99 },
          { name: "Product B", quantity: 1, price: 100.01 },
        ],
        shippingAddress: "123 Main St, Anytown, AN 12345",
        paymentMethod: "Credit Card",
      };
      setOrder(mockOrderData);
    } catch (error) {
      console.error('Error fetching order data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getActiveStep = (status) => {
    switch (status) {
      case 'Order Placed':
        return 0;
      case 'Processing':
        return 1;
      case 'Shipped':
        return 2;
      case 'Out For Delivery':
        return 3;
      case 'Delivered':
        return 4;
      default:
        return 0;
    }
  };

  const renderSection = (title, fields) => (
    <MDBox
      mb={3}
      p={3}
      borderRadius="lg"
      sx={{
        backgroundColor: '#ffffff',
        boxShadow: 3
      }}
    >
      <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>{title}</MDTypography>
      <Grid container spacing={3}>
        {fields.map((item) => (
          <Grid item xs={12} md={6} key={item.field}>
            <InlineEditField
              label={item.label}
              value={order?.[item.field] ?? ''}
              readOnly={true}
            />
          </Grid>
        ))}
      </Grid>
    </MDBox>
  );

  const renderOrderItemsTable = () => (
    <MDBox
      mb={3}
      p={3}
      borderRadius="lg"
      sx={{
        backgroundColor: '#ffffff',
        boxShadow: 3
      }}
    >
      <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>Order Items</MDTypography>
      <TableContainer component={Paper} >
        <Table sx={{ minWidth: 650 }} aria-label="order items table">
        <TableBody>
            <TableRow sx={{ backgroundColor: colors.info.main }}>
              <TableCell sx={{ borderBottom: "none", color: "#ffffff", fontWeight: "bold", padding: "16px" }}>Product Name</TableCell>
              <TableCell sx={{ borderBottom: "none", color: "#ffffff", fontWeight: "bold", padding: "16px" }} align="right">Quantity</TableCell>
              <TableCell sx={{ borderBottom: "none", color: "#ffffff", fontWeight: "bold", padding: "16px" }} align="right">Price</TableCell>
              <TableCell sx={{ borderBottom: "none", color: "#ffffff", fontWeight: "bold", padding: "16px" }} align="right">Total</TableCell>
            </TableRow>
      
        
            {order.items.map((item, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 },fontSize: 8, }}
              >
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">${item.price.toFixed(2)}</TableCell>
                <TableCell align="right">${(item.quantity * item.price).toFixed(2)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} align="right"><strong>Total</strong></TableCell>
              <TableCell align="right"><strong>${order.total.toFixed(2)}</strong></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MDBox>
  );

  const renderOrderJourneyStepper = () => (
    <MDBox
      mb={3}
      p={3}
      borderRadius="lg"
      sx={{
        backgroundColor: '#ffffff',
        boxShadow: 3
      }}
    >
      <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>Order Journey</MDTypography>
      <Stepper activeStep={getActiveStep(order.status)} alternativeLabel>
        {orderSteps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </MDBox>
  );

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Order Details: #{order.id}
                </MDTypography>
                <br />
                {renderOrderJourneyStepper()}
              </MDBox>
              <MDBox p={3}>
                
                {renderSection("Order Information", [
                  { label: "Customer Name", field: "customerName" },
                  { label: "Order Date", field: "orderDate" },
                  { label: "Status", field: "status" },
                ])}

                {renderSection("Shipping Information", [
                  { label: "Shipping Address", field: "shippingAddress" },
                ])}

                {renderSection("Payment Information", [
                  { label: "Payment Method", field: "paymentMethod" },
                ])}

                {renderOrderItemsTable()}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderDetail;