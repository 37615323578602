import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Divider,
  CircularProgress,
  TextField,
  Button,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import InlineEditField from 'components/CustonEditableInlineField/CustomEditableInlineField';
import { Edit, Save, SquareX, Plus, Minus } from 'lucide-react';

function ProductDetail() {
  const { user, axiosInstance } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [inventory, setInventory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({});
  const [quantityChange, setQuantityChange] = useState(0);

  useEffect(() => {
    fetchProductData();
  }, [id]);

  const fetchProductData = async () => {
    setIsLoading(true);
    try {
      const [productResponse, inventoryResponse] = await Promise.all([
        axiosInstance.get(`/products/${id}`),
        axiosInstance.get(`/inventory/details`)
      ]);
      setProduct(productResponse.data);
      setInventory(inventoryResponse.data.find(item => item.product_id === parseInt(id)));
    } catch (error) {
      console.error('Error fetching product data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditToggle = () => {
    if (isEditing) {
      handleSaveChanges();
    }
    setIsEditing(!isEditing);
  };

  const handleFieldUpdate = (field, value) => {
    setUpdatedFields(prev => {
      if (value !== product[field]) {
        return { ...prev, [field]: value };
      } else {
        const { [field]: _, ...rest } = prev;
        return rest;
      }
    });
  };

  const handleSaveChanges = async () => {
    if (Object.keys(updatedFields).length > 0) {
      try {
        await axiosInstance.put(`/products/${id}`, updatedFields);
        setProduct(prev => ({ ...prev, ...updatedFields }));
        setUpdatedFields({});
      } catch (error) {
        console.error('Error updating product:', error);
      }
    }
    setIsEditing(false);
  };

  const handleInventoryChange = async () => {
    try {
      await axiosInstance.post(`/inventory/adjust/${id}`, { quantity_change: quantityChange });
      fetchProductData(); // Refetch data to update inventory
      setQuantityChange(0);
    } catch (error) {
      console.error('Error adjusting inventory:', error);
    }
  };

  const renderSection = (title, fields) => (
    <MDBox 
      mb={3} 
      p={3} 
      borderRadius="lg" 
      sx={{ 
        backgroundColor: '#ffffff',
        boxShadow: 3
      }}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">{title}</MDTypography>
      </MDBox>
      <Grid container spacing={3}>
        {fields.map((item) => (
          <Grid item xs={12} md={6} key={item.field}>
            <InlineEditField
              label={item.label}
              value={product?.[item.field] ?? ''}
              options={item.options ?? null}
              onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
              isEditing={isEditing}
              readOnly={item.readOnly ?? false}
            />
          </Grid>
        ))}
      </Grid>
    </MDBox>
  );

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <MDTypography variant="h6" color="white">
                    Product Details: {product.name}
                    {!isEditing && (
                      <Edit
                        size={20}
                        color="#ffffff"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={handleEditToggle}
                      />
                    )}
                    {isEditing && (
                      <>
                        <Save
                          size={20}
                          color="#ffffff"
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                          onClick={handleSaveChanges}
                        />
                        <SquareX
                          size={20}
                          color="#ffffff"
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                          onClick={() => setIsEditing(false)}
                        />
                      </>
                    )}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox p={3}>
                {renderSection("Product Information", [
                  { label: "Name", field: "name" },
                  { label: "Description", field: "description" },
                  { label: "Product Code", field: "product_code" },
                  { label: "Category", field: "category" },
                  { label: "Brand", field: "brand" },
                  { label: "Manufacturer", field: "manufacturer" },
                ])}

                {renderSection("Pricing", [
                  { label: "Price", field: "price" },
                  { label: "Tax Rate", field: "tax_rate" },
                  { label: "Discount Percentage", field: "discount_percentage" },
                ])}

                {renderSection("Stock Information", [
                  { label: "Quantity", field: "quantity", readOnly: true },
                  { label: "Location", field: "location", readOnly: true },
                  { label: "Unit of Measurement", field: "unit_of_measurement" },
                ])}

                {renderSection("Additional Information", [
                  { label: "UPC", field: "upc" },
                  { label: "SKU", field: "sku" },
                  { label: "HSN/SAC Code", field: "hsn_sac_code" },
                  { label: "Primary Supplier", field: "primary_supplier" },
                  { label: "Supplier SKU", field: "supplier_sku" },
                  { label: "Lead Time", field: "lead_time" },
                ])}

                <MDBox 
                  mb={3} 
                  p={3} 
                  borderRadius="lg" 
                  sx={{ 
                    backgroundColor: '#ffffff',
                    boxShadow: 3
                  }}
                >
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    Inventory Management
                  </MDTypography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <MDTypography variant="body2">
                        Current Quantity: {inventory?.quantity || 0}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox display="flex" alignItems="center">
                        <Button 
                          onClick={() => setQuantityChange(prev => prev - 1)}
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          <Minus size={16} />
                        </Button>
                        <TextField
                          value={quantityChange}
                          onChange={(e) => setQuantityChange(parseInt(e.target.value) || 0)}
                          type="number"
                          variant="outlined"
                          size="small"
                          sx={{ mx: 1, width: '100px' }}
                        />
                        <Button 
                          onClick={() => setQuantityChange(prev => prev + 1)}
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          <Plus size={16} />
                        </Button>
                        <MDButton 
                          onClick={handleInventoryChange}
                          variant="gradient" 
                          color="info"
                          size="small"
                          sx={{ ml: 2 }}
                        >
                          Update Inventory
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductDetail;