import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import axios from 'axios';
import {
  Card,
  Grid,
  Modal,
  Box,
  Snackbar,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Icon,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import MapIcon from '@mui/icons-material/Map';
import Board from "@asseinfo/react-kanban";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import TableSkeleton from 'components/CustomSkeleton/TableSkeleton';
import { styled } from '@mui/material/styles';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { currencies } from 'utils/CurrencyList';

const WhiteToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: 'white',
  borderColor: 'rgba(255, 255, 255, 0.5)',
  '&.Mui-selected': {
    color: '#3a92ee',
    backgroundColor: 'white',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

function Leads() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  const { user, axiosInstance } = useAuth();
  const [currency, setCurrency] = useState(null);

  const [allLeads, setAllLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [viewMode, setViewMode] = useState('list');
  const [newCardForm, setNewCardForm] = useState(false);
  const [formValue, setFormValue] = useState("");
  const [agents, setAgents] = useState([]);
  // const [locationData, setLocationData] = useState({
  //   country: '',
  //   state: '',
  //   city: '',
  //   postalCode: '',
  // });

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loadingCountries, setLoadingCountries] = useState(false);


  const fetchLeads = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/leads/`);
      const transformedLeads = transformLeadData(response.data);
      setAllLeads(transformedLeads);
      setFilteredLeads(transformedLeads);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching leads data:', error);
    }
  };

  useEffect(() => {
    fetchLeads();
    fetchAgents();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      setLoadingCountries(true);
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const sortedCountries = response.data
          .map(country => ({
            code: country.cca2,
            label: country.name.common
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCountries(sortedCountries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
      setLoadingCountries(false);
    };

    fetchCountries();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await axiosInstance.get('/agents/');
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  useEffect(() => {
    const filtered = allLeads.filter(lead => {
      return Object.entries(filters).every(([key, value]) => {
        return lead[key].toLowerCase().includes(value.toLowerCase());
      });
    });
    setFilteredLeads(filtered);
  }, [filters, allLeads]);

  const fetchLocationData = async (input, type) => {
    // In a real implementation, this would be an API call
    return new Promise((resolve) => {
      setTimeout(() => {
        if (type === 'postalCode') {
          resolve({
            country: 'United States',
            state: 'California',
            city: 'San Francisco',
          });
        } else if (type === 'city') {
          resolve({
            country: 'United States',
            state: 'California',
          });
        }
      }, 500);
    });
  };


  const handleCurrencyChange = (event, newValue) => {
    setCurrency(newValue);
  };

  // useEffect(() => {
  //   // Update form fields when locationData changes
  //   Object.entries(locationData).forEach(([key, value]) => {
  //     const field = document.querySelector(`input[name=${key}]`);
  //     if (field) {
  //       field.value = value;
  //     }
  //   });
  // }, [locationData]);

  const transformLeadData = (serverData) => {
    return serverData.map(lead => ({
      id: lead.id,
      name: lead.first_name + ' ' + lead.last_name || "N/A",
      company: lead.company_name || "N/A",
      source: lead.lead_source || "N/A",
      email: lead.email || "N/A",
      status: lead.lead_status || "N/A",
      lastActivity: lead.updated_at?.split('T')[0] || "N/A",
      lead_rating: "7.4" || "N/A",  // TODO: Replace with real data
      owner_name: lead.owner?.name || "N/A",
      owner_avatar: lead.owner?.avatar || "https://via.placeholder.com/24",
      assigned_agent: lead.assigned_agent || null,
    }));
  };

  const handleOpenModal = () => setIsModalOpen(true);
  // const handleCloseModal = () => setIsModalOpen(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCountry(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSaveLead = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const formData = new FormData(event.target);
    
    const newLead = {
      first_name: formData.get('first_name') || null,
      last_name: formData.get('last_name') || null,
      title: formData.get('title') || null,
      email: formData.get('email') || null,
      phone: formData.get('phone') || null,
      address: formData.get('address') || null,
      state: formData.get('state') || null,
      country: selectedCountry ? selectedCountry.label : null,
      city: formData.get('city') || null,
      postal_code: formData.get('postal_code') || null,
      website: formData.get('website') || null,
      company_name: formData.get('company_name'),
      industry: formData.get('industry') || null,
      annual_revenue: formData.get('annual_revenue') ? parseFloat(formData.get('annual_revenue')) : null,
      currency: currency.code || null,
      number_of_employees: formData.get('number_of_employees') ? parseInt(formData.get('number_of_employees'), 10) : null,
      notes: formData.get('notes') || null,
      lead_source: formData.get('lead_source') || null,
      lead_status: formData.get('lead_status') || null,
      lead_rating: formData.get('lead_rating') || null,
      user_id: user.id,
    };

    console.log(`New lead:`, newLead);
    

    // Remove null or undefined values
    const filteredLead = Object.fromEntries(
      Object.entries(newLead).filter(([_, v]) => v != null && v !== '')
    );

    console.log(`Filtered lead:`, filteredLead);

    try {
      handleCloseModal();
      const response = await axiosInstance.post(`/leads/`, filteredLead, {});
      setAllLeads(prevLeads => [...prevLeads, transformLeadData([response.data])[0]]);
      await fetchLeads();
      setSnackbar({
        open: true,
        message: 'Lead created successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error creating new lead:', error);
      setSnackbar({
        open: true,
        message: 'Error creating lead. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowClick = (rowData) => {
    navigate(`/lead/detail/${rowData.id}`);
  };

  const handleAgentAssignment = async (leadId, agentId) => {
    try {
      await axiosInstance.patch(`/leads/${leadId}/`, { assigned_agent: agentId });
      fetchLeads(); // Refresh leads after assignment
    } catch (error) {
      console.error('Error assigning agent:', error);
      setSnackbar({
        open: true,
        message: 'Failed to assign agent',
        severity: 'error',
      });
    }
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const openNewCardForm = (event, id) => setNewCardForm(id);
  const closeNewCardForm = () => setNewCardForm(false);
  const handeSetFormValue = ({ currentTarget }) => setFormValue(currentTarget.value);

  const leadsTableData = useMemo(() => ({
    columns: [
      { 
        Header: "Name", 
        accessor: "name", 
        width: "15%",
        Cell: ({ row }) => (
          <MDTypography 
            component="span" 
            onClick={() => handleRowClick(row.original)}
            variant="caption" 
            color="text" 
            fontWeight="medium"
            sx={{ cursor: 'pointer' }}
          >
            {row.original.name}
          </MDTypography>
        )
      },
      { Header: "Company", accessor: "company", width: "15%" },
      { Header: "Email", accessor: "email", width: "15%" },
      { Header: "Status", accessor: "status", width: "10%" },
      { Header: "Source", accessor: "source", width: "10%" },
      { Header: "Lead Rating", accessor: "lead_rating", width: "10%" },
      { Header: "Last Activity", accessor: "lastActivity", width: "10%" },
      {
        Header: "Assigned Agent",
        accessor: "assigned_agent",
        width: "15%",
        Cell: ({ row }) => (
          <FormControl fullWidth>
            <Select
              value={row.original.assigned_agent || ''}
              onChange={(e) => handleAgentAssignment(row.original.id, e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {agents.map((agent) => (
                <MenuItem key={agent.id} value={agent.id}>
                  {agent.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ),
      },
    ],
    rows: filteredLeads,
  }), [filteredLeads, agents]);

  const kanbanColumns = useMemo(() => [
    { id: 'new', title: 'New' },
    { id: 'contacted', title: 'Contacted' },
    { id: 'qualified', title: 'Qualified' },
    { id: 'proposal', title: 'Proposal' },
    { id: 'won', title: 'Won' },
    { id: 'lost', title: 'Lost' },
  ], []);

  const getLeadsByStatus = useMemo(() => {
    const leadsByStatus = {
      new: { id: 'new', title: 'New', cards: [] },
      contacted: { id: 'contacted', title: 'Contacted', cards: [] },
      qualified: { id: 'qualified', title: 'Qualified', cards: [] },
      proposal: { id: 'proposal', title: 'Proposal', cards: [] },
      won: { id: 'won', title: 'Won', cards: [] },
      lost: { id: 'lost', title: 'Lost', cards: [] },
    };
    
    filteredLeads.forEach(lead => {
      const status = lead.status.toLowerCase();
      if (leadsByStatus[status]) {
        leadsByStatus[status].cards.push({
          id: lead.id.toString(),
          title: lead.name,
          description: `${lead.company} - ${lead.email}`,
          owner: lead.owner ? {
            name: lead.owner.name || 'Unknown',
            avatar: lead.owner.avatar || 'https://via.placeholder.com/24',
          } : {
            name: 'Unknown',
            avatar: 'https://via.placeholder.com/24',
          },
        });
      }
    });
    
    return Object.values(leadsByStatus);
  }, [filteredLeads]);

  const onCardDragEnd = (board, card, source, destination) => {
    if (source.fromColumnId !== destination.toColumnId) {
      const newStatus = destination.toColumnId;
      const leadId = parseInt(card.id);

      setFilteredLeads(prevLeads => {
        return prevLeads.map(lead => 
          lead.id === leadId ? { ...lead, status: newStatus } : lead
        );
      });

      // Update the lead status in the backend
      axiosInstance.patch(`/leads/${leadId}/`, { lead_status: newStatus })
        .then(response => {
          console.log('Lead status updated successfully');
        })
        .catch(error => {
          console.error('Error updating lead status:', error);
          // Revert the change in the UI if the API call fails
          setFilteredLeads(prevLeads => {
            return prevLeads.map(lead => 
              lead.id === leadId ? { ...lead, status: source.fromColumnId } : lead
            );
          });
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="leaderboard"
                title="Total Leads"
                count={allLeads.length}
                percentage={{
                  color: "success",
                  amount: "+50%",
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="person_add"
                title="New Leads"
                count={allLeads.length}
                percentage={{
                  color: "success",
                  amount: "+25%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Conversion Rate"
                count="3.5%"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Leads
                </MDTypography>
                <MDBox display="flex" alignItems="center">
                    <ToggleButtonGroup
                      value={viewMode}
                      exclusive
                      onChange={handleViewModeChange}
                      aria-label="view mode"
                      sx={{
                        mr: 2,
                        backgroundColor: 'transparent',
                        border: 'none',
                        '& .MuiToggleButtonGroup-grouped': {
                          border: '1px solid rgba(255, 255, 255, 0.5)',
                          '&:not(:first-of-type)': {
                            borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
                          },
                        },
                      }}
                    >
                      <WhiteToggleButton value="list" aria-label="list view">
                        <Icon sx={{ color: 'inherit' }}>view_list</Icon>
                      </WhiteToggleButton>
                      <WhiteToggleButton value="kanban" aria-label="kanban view">
                        <Icon sx={{ color: 'inherit' }}>view_kanban</Icon>
                      </WhiteToggleButton>
                    </ToggleButtonGroup>
                    <MDButton variant="gradient" color="dark" onClick={handleOpenModal} sx={{ mr: 2 }}>
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;Add New Lead
                    </MDButton>
                    <MDButton variant="gradient" color="info" onClick={() => navigate('/agent-map')}>
                      <MapIcon sx={{ mr: 1 }} />
                      View Agent Map
                    </MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {!isLoading ? (
                  viewMode === 'list' ? (
                    <DataTable
                      table={leadsTableData}
                      isSorted={true}
                      entriesPerPage={{
                        defaultValue: 10,
                        entries: [5, 10, 15, 20, 25],
                      }}
                      canSearch
                      noEndBorder
                      showTotalEntries
                      pagination={{ variant: "gradient", color: "info" }}
                      searchPlaceholder="Search Lead"
                      exportCSV={true}
                    />
                  ) : (
                    <MDBox
                      sx={{
                        "& .react-kanban-column": {
                          backgroundColor: ({ palette: { background } }) => `${background.default}E6`,  // Light grey with 90% opacity
                          width: "350px",
                          margin: "0 10px",
                          padding: "20px",
                          borderRadius: "16px",  // Rounded edges
                          boxShadow: 3,
                        },
                        "& .react-kanban-column-header": {
                          backgroundColor: ({ palette: { info } }) => info.main,
                          color: 'white',
                          padding: '10px',
                          borderRadius: '16px 16px 0 0',  // Rounded top edges
                        },
                      }}
                    >
                      <Board
                        initialBoard={{ columns: getLeadsByStatus }}
                        allowAddCard
                        onCardDragEnd={onCardDragEnd}
                        renderColumnHeader={({ id, title }, { addCard }) => (
                          <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                            <MDTypography variant="h6">{title}</MDTypography>
                            <MDButton size="small" iconOnly onClick={(event) => openNewCardForm(event, id)}>
                              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            </MDButton>
                          </MDBox>
                        )}
                        renderCard={({ id, title, description, owner }, { dragging }) => (
                          <MDBox
                            key={id}
                            dragging={dragging.toString() || undefined}
                            display="block"
                            width="calc(350px - 40px)"
                            bgColor="white"
                            color="text"
                            borderRadius="xl"
                            mt={2.5}
                            py={1.875}
                            px={1.875}
                            lineHeight={1.5}
                            sx={{
                              fontSize: ({ typography: { size } }) => size.md,
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                              transition: "box-shadow 0.3s ease-in-out",
                              "&:hover": {
                                cursor: "pointer",
                                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                              },
                            }}
                            onClick={() => handleRowClick({ id: parseInt(id) })}
                          >
                            <MDBox display="flex" alignItems="center" mb={1}>
                              <Avatar
                                src={owner.avatar}
                                alt={owner.name}
                                sx={{ width: 24, height: 24, mr: 1 }}
                              />
                              <MDTypography variant="body2" fontWeight="bold">
                                {title}
                              </MDTypography>
                            </MDBox>
                            <MDTypography variant="caption" color="text">
                              {description}
                            </MDTypography>
                          </MDBox>
                        )}
                      />
                    </MDBox>
                  )
                ) : (
                  <TableSkeleton />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      </MDBox>
      
      <Footer />
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {xs: '90%', sm: '80%', md: '70%', lg: '60%'},
          maxWidth: 800,
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            Add New Lead
          </MDTypography>
          <form onSubmit={handleSaveLead}>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="first_name" label="First Name" variant="outlined" margin="normal" required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="last_name" label="Last Name" variant="outlined" margin="normal" required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="title" label="Title" variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="email" label="Email" variant="outlined" margin="normal" required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="phone" label="Phone" variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="company_name" label="Company Name" variant="outlined" margin="normal" required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="address" label="Address" variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <TextField fullWidth name="country" label="Country" variant="outlined" margin="normal" /> */}
                <Autocomplete
                    options={countries}
                    loading={loadingCountries}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        name="country"
                        label="Country"
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingCountries ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    value={selectedCountry}
                    onChange={(event, newValue) => {
                      setSelectedCountry(newValue);
                    }}
                  />
              </Grid> 
              <Grid item xs={12} sm={6}>
                <TextField fullWidth 
                  name="state" 
                  label="State" 
                  variant="outlined" 
                  margin="normal" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="city"
                  label="City"
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="postal_code"
                  label="Postal Code"
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="website" label="Website" variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <TextField fullWidth name="industry" label="Industry" variant="outlined" margin="normal" /> */}
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Industry</InputLabel>
                  <Select
                    name="industry"
                    label="Industry"
                    defaultValue=""
                    sx={{ 
                      height: 45,
                      '& .MuiSelect-icon': {
                        display: 'block',
                      },
                    }}
                    IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  
                    {[
                      "Automotive", 
                      "Banking", 
                      "Construction", 
                      "Education", 
                      "Financial Services", 
                      "Government", 
                      "Healthcare", 
                      "Manufacturing", 
                      "Media", 
                      "Retail", 
                      "Technology", 
                      "Travel", 
                      "Other"
                    ].map((industry) => (
                      <MenuItem key={industry} value={industry}>
                        {industry}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="annual_revenue" label="Annual Revenue" variant="outlined" margin="normal" type="number" />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <TextField fullWidth name="currency" label="Currency" variant="outlined" margin="normal" /> */}
                {/* <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Currency</InputLabel>
                  <Select
                    name="currency"
                    label="Currency"
                    defaultValue=""
                    sx={{
                      height: 45,
                      '& .MuiSelect-icon': {
                        display: 'block',
                      },
                    }}
                    IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.name} ({option.code})
                      </MenuItem>
                    ))} 
                  </Select>
                </FormControl> */}
                <Autocomplete
                  options={currencies}
                  getOptionLabel={(option) => `${option.name} (${option.code})`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Currency"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  value={currency}
                  onChange={handleCurrencyChange}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.name} ({option.code})
                    </li>
                  )}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: 45,
                    },
                  }}
                  popupIcon={<ArrowDropDown style={{ transform: 'scale(1)', marginRight: '10px' }} />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="number_of_employees" label="Number of Employees" variant="outlined" margin="normal" type="number" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth name="notes" label="Notes" variant="outlined" margin="normal" multiline rows={3} />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField fullWidth name="lead_source" label="Lead Source" variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="lead_status" label="Lead Status" variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth name="lead_rating" label="Lead Rating" variant="outlined" margin="normal" />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Lead Source</InputLabel>
                  <Select
                    name="lead_source"
                    label="Lead Source"
                    defaultValue=""
                    sx={{ 
                      height: 45,
                      '& .MuiSelect-icon': {
                        display: 'block',
                      },
                    }}
                    IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {[
                      "Website",
                      "Referral",
                      "Social Media",
                      "Email Campaign",
                      "Trade Show",
                      "Cold Call",
                      "Advertisement",
                      "Other"
                    ].map((source) => (
                      <MenuItem key={source} value={source}>
                        {source}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Lead Status</InputLabel>
                  <Select
                    name="lead_status"
                    label="Lead Status"
                    defaultValue=""
                    sx={{ 
                      height: 45,
                      '& .MuiSelect-icon': {
                        display: 'block',
                      },
                    }}
                    IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {[
                      "New",
                      "Contacted",
                      "Qualified",
                      "Proposal Sent",
                      "Negotiation",
                      "Closed Won",
                      "Closed Lost"
                    ].map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Lead Rating</InputLabel>
                  <Select
                    name="lead_rating"
                    label="Lead Rating"
                    defaultValue=""
                    sx={{ 
                      height: 45,
                      '& .MuiSelect-icon': {
                        display: 'block',
                      },
                    }}
                    IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {[
                      "Hot",
                      "Warm",
                      "Cold"
                    ].map((rating) => (
                      <MenuItem key={rating} value={rating}>
                        {rating}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
              <MDButton onClick={handleCloseModal} color="secondary" sx={{ marginRight: 2 }}>
                Close
              </MDButton>
              <MDButton type="submit" variant="gradient" color="info">
                Save Lead
              </MDButton>
            </MDBox>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default Leads;
