const calendarEventsData = [
  {
    title: "Meeting with team",
    start: new Date(new Date().setHours(new Date().getHours() + 1)),
    end: new Date(new Date().setHours(new Date().getHours() + 2)),
    className: "success",
  },
  {
    title: "Lunch meeting",
    start: new Date(new Date().setDate(new Date().getDate() + 1)),
    end: new Date(new Date().setDate(new Date().getDate() + 1)),
    className: "info",
  },
  {
    title: "Marketing event",
    start: new Date(new Date().setDate(new Date().getDate() + 3)),
    end: new Date(new Date().setDate(new Date().getDate() + 5)),
    className: "warning",
  },
  {
    title: "Product launch",
    start: new Date(new Date().setDate(new Date().getDate() + 7)),
    allDay: true,
    className: "error",
  },
];

export default calendarEventsData;
