import React from "react";
import { Card, Icon, List, ListItem, ListItemText, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DailyPlan() {
  const tasks = [
    { id: 1, text: "Review project proposal", completed: false },
    { id: 2, text: "Client meeting at 2 PM", completed: false },
    { id: 3, text: "Send follow-up emails", completed: true },
    { id: 4, text: "Prepare weekly report", completed: false },
  ];

  return (
    <Card>
      <MDBox p={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <MDTypography variant="h6" fontWeight="medium">
            Plan for Today
          </MDTypography>
          <Icon color="action">event</Icon>
        </MDBox>
        <List>
          {tasks.map((task) => (
            <ListItem key={task.id} dense button>
              <Checkbox
                edge="start"
                checked={task.completed}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText 
                primary={
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color={task.completed ? "text" : "dark"}
                    style={{ textDecoration: task.completed ? 'line-through' : 'none' }}
                  >
                    {task.text}
                  </MDTypography>
                }
              />
            </ListItem>
          ))}
        </List>
      </MDBox>
    </Card>
  );
}

export default DailyPlan;
