import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "auth/Auth";
import PropTypes from "prop-types";

import AppBar from "@mui/material/AppBar";
import { Avatar } from "@mui/material";
import { deepOrange } from '@mui/material/colors';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Breadcrumbs from "examples/Breadcrumbs";
import DarkModeToggle from "components/CustomDarkMode";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "examples/Navbars/DashboardNavbar/styles";

import {
  useMaterialUIController,
  setTransparentNavbar,
} from "context";

function DashboardNavbar({ absolute, light, isMini }) {
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const {user, axiosInstance} = useAuth();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    // ... (keep existing useEffect logic)

    // Fetch notifications
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/notifications/${user.id}`);
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
      setLoading(false);
    };

    fetchNotifications();
    // Set up an interval to fetch notifications periodically (e.g., every 2 minutes)
    const intervalId = setInterval(fetchNotifications, 2 * 60 * 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [user.id]);

  const handleMarkAsRead = async (notificationId) => {
    try {
      await axiosInstance.post(`/notifications/mark_as_read/${notificationId}`, {});
      setNotifications(notifications.map(notification => 
        notification.id === notificationId ? { ...notification, is_read: true } : notification
      ));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => setOpenMenu(false);

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {loading ? (
        <>
          <MenuItem>
            <Skeleton variant="rectangular" width={150} height={12} />
          </MenuItem>
          <MenuItem>
            <Skeleton variant="rectangular" width={150} height={12} />
          </MenuItem>
          <MenuItem>
            <Skeleton variant="rectangular" width={150} height={12} />
          </MenuItem>
          <MenuItem>
            <Skeleton variant="rectangular" width={150} height={12} />
          </MenuItem>
        </>
      ) : notifications.length === 0 ? (
        <MenuItem disabled sx={{ width: 200, height: 30 }}>No notifications</MenuItem>
      ) : (
        notifications.map((notification) => (
          <MenuItem 
            key={notification.id} 
            onClick={() => handleMarkAsRead(notification.id)}
            sx={{
              color: notification.is_read ? 'grey.500' : 'text.primary',
              fontWeight: notification.is_read ? 'normal' : 'bold',
              display: 'flex',
              alignItems: 'center',
              width: 300,
              height: 30,
              padding: '0px 8px',
              minHeight: 'unset',
            }}
          >
            {!notification.is_read && (
              <Badge 
                color="primary" 
                variant="dot" 
                sx={{ mr: 1, transform: 'scale(0.7)' }}
              />
            )}
            <Typography 
              noWrap 
              sx={{ 
                flex: 1, 
                fontSize: '0.8rem', 
                lineHeight: 1,
              }}
            >
              {notification.message}
            </Typography>
          </MenuItem>
        ))
      )}
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });


  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end" p={2}>
          <DarkModeToggle />
        </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
            <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Badge 
                  badgeContent={notifications.filter(n => !n.is_read).length} 
                  color="error"
                >
                  <Icon sx={iconsStyle}>notifications</Icon>
                </Badge>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleSettingsClick}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              {renderMenu()}
            </MDBox>
            <Avatar
                sx={{ bgcolor: deepOrange[500] }}
                alt="Remy Sharp"
                src="/broken-image.jpg"
                onClick={() => navigate('/profile')}
              >
                A
              </Avatar>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
