import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  TextField,
  Autocomplete,
  MenuItem,
} from '@mui/material';
import CustomInputForm from 'components/CustomInputForm';
import { Download, Edit } from 'lucide-react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Plus, Minus } from 'lucide-react';

const Quotations = ({ quotations = []}) => {
    
    const navigate = useNavigate();

    const handleQuotationClick = (quotation) => {
        navigate(`/quote/detail/${quotation.id}`);
      };


  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    
    // Add company logo or name
    doc.setFontSize(20);
    doc.text('ParaCloud', 105, 15, { align: 'center' });

    // Add a table with more details if needed
    const tableData = [
      ['Quatation ID', quotations.id],
      ['Created At', formatDate(quotations.created_at)],
      ['Last Updated', formatDate(quotations.updated_at)],
      ['Quote Date', formatDate(quotations.quote_date)],
      ['Total Amount', formatCurrency(quotations.total_amount)],
    ];
    
    doc.autoTable({
      startY: 60,
      head: [['Detail', 'Value']],
      body: tableData,
    });
    
    // Save the PDF
    doc.save(`Quotation_${quotations.id}.pdf`);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  };

  const getStatusColor = (status) => {
    switch(status.toLowerCase()) {
      case 'sent':
        return 'info';
      case 'draft':
        return 'warning';
      case 'accepted':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <MDBox>
      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: 'fixed', width: '100%'  }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <MDBox display="flex" justifyContent="center" width="100%" >
                  Quote Date
                </MDBox>
              </TableCell>
              <TableCell>
                <MDBox display="flex" justifyContent="center" width="100%" marginLeft="46px">
                  Total Amount
                </MDBox>
              </TableCell>
              <TableCell>
                <MDBox display="flex" justifyContent="center" width="100%" marginLeft="40px">
                  Status
                </MDBox>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { !quotations || quotations.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <MDTypography variant="body2" color="textSecondary">
                    No quotations found
                  </MDTypography>
                </TableCell>
              </TableRow>
            ) : (
              quotations.map((quotation) => (
                <TableRow
                  key={quotation.id}
                  hover
                  onClick={() => handleQuotationClick(quotation)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{formatDate(quotation.quote_date)}</TableCell>
                  <TableCell>{formatCurrency(quotation.total_amount)}</TableCell>
                  <TableCell>
                    <MDTypography
                      component="span"
                      variant="caption"
                      color="white"
                      fontWeight="medium"
                      sx={{
                        backgroundColor: (theme) => theme.palette[getStatusColor(quotation.status)].main,
                        padding: '4px 8px',
                        borderRadius: '4px',
                        display: 'inline-block',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {quotation.status}
                    </MDTypography>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </MDBox>
  );
};

export default Quotations;