import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Modal,
  Box,
  TextField,
  MenuItem,
  Select,
  Snackbar,
  Alert,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Icon from "@mui/material/Icon";
import TableSkeleton from 'components/CustomSkeleton/TableSkeleton';
import CustomInputForm from 'components/CustomInputForm';

function Accounts() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setfilteredAccounts] = useState([]);
  const [managers, setManagers] = useState({});
  const [managerList, setManagerList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { user, axiosInstance } = useAuth();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const fetchAccounts = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/accounts/`);
      const transformedAccounts = transformAccountData(response.data);
      setAccounts(transformedAccounts);
      setfilteredAccounts(transformedAccounts);
    } catch (error) {
      console.error('Error fetching accounts data:', error);
      setSnackbar({
        open: true,
        message: 'Error fetching accounts. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchManagers = async () => {
    try {
      const response = await axiosInstance.get(`/users/`);
      const managerList = response.data.map(user => user.username);
      setManagerList(managerList);
      const managersData = response.data.map(user => ({
        id: user.id,
        username: user.username,
      }));
      
      setManagers(managersData);
      console.log('managers:', managers);
      
    } catch (error) {
      console.error('Error fetching managers:', error);
    }
  };

  const transformAccountData = (serverData) => {
    return serverData.map(account => ({
      id: account.id,
      manager_id: account.manager_id,
      name: account.name,
      industry: account.industry,
      account_status: account.account_status,
      annual_revenue: account.annual_revenue,
      updated_at: account.updated_at?.split('T')[0] || "N/A",
    }));
  };
  
  useEffect(() => {
    fetchAccounts();
    fetchManagers();
  }, []);


  // Modal handlers
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveAccount = async (formData) => {
    
    setIsLoading(true);
    handleCloseModal();
    try {
    
      // Remove any properties with undefined values
      // Object.keys(formData).forEach(key => formData[key] === undefined && delete formData[key]);
    
      const response = await axiosInstance.post(`/accounts/`, formData);
      
      await fetchAccounts();
      setSnackbar({
        open: true,
        message: 'Account created successfully.',
        severity: 'success',
      })
    } catch (error) {
      console.error('Error creating new account:', error);
      setSnackbar({
        open: true,
        message: 'Error creating new account. Please try again.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false);
    }
  };

  // Navigation handler
  const handleRowClick = (rowData) => {
    navigate(`/account/detail/${rowData.id}`);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fields = [
    { name: 'name', label: 'Name', type: 'text', required: true },
    { name: 'account_type', label: 'Account Type', type: 'select', required: true, options: [
      { value: 'Customer', label: 'Customer' },
      { value: 'Partner', label: 'Partner' },
      { value: 'Prospect', label: 'Prospect' }
    ]},
    { name: 'industry', label: 'Industry', type: 'select', required: true, options: [
      { value: 'Technology', label: 'Technology' },
      { value: 'Finance', label: 'Finance' },
      { value: 'Healthcare', label: 'Healthcare' },
      { value: 'Education', label: 'Education' },
      { value: 'Other', label: 'Other' }
    ]},
    { name: 'account_status', label: 'Account Status', type: 'select', required: true, options: [
      { value: 'Active', label: 'Active' },
      { value: 'Inactive', label: 'Inactive' },
      { value: 'Pending', label: 'Pending' }
    ]},
    { name: 'annual_revenue', label: 'Annual Revenue', type: 'number', prefix: '$' },
    { name: 'number_of_employees', label: 'Number of Employees', type: 'number' },
    { name: 'website', label: 'Website', type: 'text', inputType: 'url' },
    { name: 'phone', label: 'Phone', type: 'text', inputType: 'tel' },
    { name: 'email', label: 'Email', type: 'text', inputType: 'email', required: true },
    { name: 'billing_address', label: 'Billing Address', type: 'text', multiline: true, rows: 3 },
    { name: 'billing_city', label: 'Billing City', type: 'text' },
    { name: 'billing_state', label: 'Billing State', type: 'text' },
    { name: 'billing_country', label: 'Billing Country', type: 'text' },
    { name: 'billing_postal_code', label: 'Billing Postal Code', type: 'text' },
    { name: 'shipping_address', label: 'Shipping Address', type: 'text', multiline: true, rows: 3 },
    { name: 'shipping_city', label: 'Shipping City', type: 'text' },
    { name: 'shipping_state', label: 'Shipping State', type: 'text' },
    { name: 'shipping_country', label: 'Shipping Country', type: 'text' },
    { name: 'shipping_postal_code', label: 'Shipping Postal Code', type: 'text' },
    { name: 'notes', label: 'Notes', type: 'text', multiline: true, rows: 3 },
    { name: 'tags', label: 'Tags (comma-separated)', type: 'list' },
    { name: 'manager_id', label: 'Managers', type: 'userpicklist', required: true, options: managers },
  ];
  
  
  // Update the accountsTableData
  const accountsTableData = useMemo(() => ({
    columns: [
      { Header: "ID", accessor: "id", width: "1%" },
      { 
        Header: "Name", 
        accessor: "name", 
        width: "15%",
        Cell: ({ row }) => (
          <MDTypography 
            component="span" 
            onClick={() => handleRowClick(row.original)}
            variant="caption" 
            color="text" 
            fontWeight="medium"
            sx={{ cursor: 'pointer' }}
          >
            {row.original.name}
          </MDTypography>
        )
      },
      { Header: "Industry", accessor: "industry", width: "15%" },
      { Header: "Account Status", accessor: "account_status", width: "10%" },
      { Header: "Annual Revenue", accessor: "annual_revenue", width: "10%" },
      { Header: "Manager Name", accessor: "manager_id", width: "10%" },
      { Header: "Last Activity", accessor: "updated_at", width: "10%" },
    ],
    rows: filteredAccounts,
  }), [filteredAccounts]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="business"
                title="Total Accounts"
                count={accounts.length}
                percentage={{
                  color: "success",
                  amount: "+5%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="check_circle"
                title="Active Accounts"
                count={accounts.filter(account => account.account_status === "Active").length}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="leaderboard"
                title="Account Growth"
                count="7.5%"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="New Accounts"
                count="25"
                percentage={{
                  color: "success",
                  amount: "+10%",
                  label: "than last quarter",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        
        <MDBox mt={4.5}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Accounts
                  </MDTypography>
                  <MDButton variant="gradient" color="dark" onClick={handleOpenModal}>
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Add New Account
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  {isLoading ? (
                    <TableSkeleton />
                  ) : (
                    <>
                      <DataTable
                        table={accountsTableData}
                        isSorted={true}
                        entriesPerPage={{
                          defaultValue: 10,
                          entries: [5, 10, 15, 20, 25],
                        }}
                        showTotalEntries
                        pagination={{ variant: "gradient", color: "info" }}
                        noEndBorder
                        canSearch
                        searchPlaceholder="Search Account"
                        exportCSV={true}
                      />
                    </>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />

      {/* Modal for adding new account */}
      <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: 800,
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
      }}>
        <MDTypography id="modal-modal-title" variant="h6" component="h2" mb={2}>
          Add New Account
        </MDTypography>

        <CustomInputForm 
          fields={fields}
          onSubmit={handleSaveAccount}
          onCancel={handleCloseModal}
        />

      </Box>
    </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

    </DashboardLayout>
  );
}

export default Accounts;