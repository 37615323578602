import React, { useState } from 'react';
import {
  Card,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function EmailSetup() {
  const [emailConfig, setEmailConfig] = useState({
    provider: '',
    email: '',
    password: '',
    imapHost: '',
    imapPort: '',
    smtpHost: '',
    smtpPort: '',
  });

  const handleInputChange = (e) => {
    setEmailConfig({ ...emailConfig, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send this data to your backend
    console.log('Email configuration:', emailConfig);
    // Add logic to save configuration
  };

  const handleTestConnection = () => {
    // Add logic to test the connection
    console.log('Testing connection with:', emailConfig);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Email Connection Setup
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        select
                        label="Email Provider"
                        name="provider"
                        value={emailConfig.provider}
                        onChange={handleInputChange}
                        margin="normal"
                      >
                        <MenuItem value="gmail">Gmail</MenuItem>
                        <MenuItem value="outlook">Outlook</MenuItem>
                        <MenuItem value="custom">Custom</MenuItem>
                      </TextField>
                      <TextField
                        fullWidth
                        label="Email Address"
                        name="email"
                        value={emailConfig.email}
                        onChange={handleInputChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Password"
                        name="password"
                        type="password"
                        value={emailConfig.password}
                        onChange={handleInputChange}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="IMAP Host"
                        name="imapHost"
                        value={emailConfig.imapHost}
                        onChange={handleInputChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="IMAP Port"
                        name="imapPort"
                        value={emailConfig.imapPort}
                        onChange={handleInputChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="SMTP Host"
                        name="smtpHost"
                        value={emailConfig.smtpHost}
                        onChange={handleInputChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="SMTP Port"
                        name="smtpPort"
                        value={emailConfig.smtpPort}
                        onChange={handleInputChange}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
                    <MDButton 
                      onClick={handleTestConnection} 
                      variant="outlined" 
                      color="info" 
                      sx={{ mr: 2 }}
                    >
                      Test Connection
                    </MDButton>
                    <MDButton type="submit" variant="gradient" color="info">
                      Save Configuration
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmailSetup;
