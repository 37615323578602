import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function Activities({ activities = [] }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Lead Activity
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              0
            </MDTypography>{" "}
            activity today
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {activities.length > 0 ? (
          activities.map((activity, index) => (
            <TimelineItem
              key={index}
              color={activity.color || "info"}
              icon={activity.icon || "event"}
              title={activity.description || "No description"}
              dateTime={activity.date || "No date"}
            />
          ))
        ) : (
          <MDTypography variant="body2" color="text">
            No activities to display.
          </MDTypography>
        )}
      </MDBox>
    </Card>
  );
}

Activities.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      icon: PropTypes.string,
      description: PropTypes.string,
      date: PropTypes.string,
    })
  ),
};

export default Activities;