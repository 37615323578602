import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";

function BasicLayout({ children, image }) {
  return (
    <PageLayout>
      <Grid container sx={{ height: "100vh" }}>
        <Grid item xs={12} lg={6} sx={{ p: 0 }}>
          <MDBox
            display={{ xs: "none", lg: "flex" }}
            width="100%"
            height="100%"
            sx={{
              backgroundImage: `url(${image})`,
              // backgroundImage: `url('https://hebbkx1anhila5yf.public.blob.vercel-storage.com/new_login_image-SPlLyKEx0sqTORPAcOIj3RULPWiefU.jpg')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Grid>
        <Grid 
          item 
          xs={12} 
          sm={10} 
          md={7} 
          lg={6} 
          xl={4} 
          sx={{ 
            mx: "auto",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <MDBox py={3} px={3}>
            {children}
          </MDBox>
        </Grid>
      </Grid>
      <Footer light />
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BasicLayout;