import React from "react";
import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function EmailActivity() {
  const emailData = [
    { type: "Sent", count: 45 },
    { type: "Received", count: 32 },
    { type: "Unread", count: 5 },
  ];

  return (
    <Card>
      <MDBox p={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <MDTypography variant="h6" fontWeight="medium">
            Email Activity
          </MDTypography>
          <Icon color="action">email</Icon>
        </MDBox>
        {emailData.map((item) => (
          <MDBox key={item.type} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {item.type}
            </MDTypography>
            <MDTypography variant="button" fontWeight="medium">
              {item.count}
            </MDTypography>
          </MDBox>
        ))}
      </MDBox>
    </Card>
  );
}

export default EmailActivity;
