import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  CircularProgress,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Collapse,
  TextField,
  Input,
} from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useAuth } from '../../auth/Auth';

function QuotationDetail() {
  const { id } = useParams();
  const { axiosInstance } = useAuth();
  const [quotation, setQuotation] = useState(null);
  const [quotationItems, setQuotationItems] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [salesOrder, setSalesOrder] = useState(null);
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [poNumber, setPoNumber] = useState('');
  const [soNumber, setSoNumber] = useState('');
  const [poFile, setPoFile] = useState(null);
  const [soFile, setSoFile] = useState(null);
  const [showPoUpload, setShowPoUpload] = useState(false);
  const [showSoUpload, setShowSoUpload] = useState(false);

  useEffect(() => {
    fetchQuotationDetails();
    fetchOrderDetails();
  }, [id]);

  // const fetchQuotationDetails = async () => {
  //   try {
  //     const [quotationResponse, itemsResponse, poResponse, soResponse, orderResponse] = await Promise.all([
  //       axiosInstance.get(`/quotations/${id}`),
  //       axiosInstance.get(`/quotations/${id}/items`),
  //       axiosInstance.get(`/quotations/${id}/purchase-order`),
  //       axiosInstance.get(`/quotations/${id}/sales-order`),
  //       axiosInstance.get(`/quotations/${id}/order`),
  //     ]);
  //     setQuotation(quotationResponse.data);
  //     setQuotationItems(itemsResponse.data);
  //     setPurchaseOrder(poResponse.data);
  //     setSalesOrder(soResponse.data);
  //     setOrder(orderResponse.data);
  //     setIsLoading(false);
  //   } catch (err) {
  //     console.error('Error fetching quotation details:', err);
  //     setError('Failed to fetch quotation details. Please try again.');
  //     setIsLoading(false);
  //   }
  // };


  // const fetchQuotationDetails = async () => {
  //   try {
  //     const [quotationResponse, itemsResponse] = await Promise.all([
  //       axiosInstance.get(`/quotations/${id}`),
  //       axiosInstance.get(`/quotations/${id}/items`),
  //     ]);
  //     setQuotation(quotationResponse.data);
  //     setQuotationItems(itemsResponse.data);

  //     // Fetch additional data separately and handle potential 404s
  //     try {
  //       const poResponse = await axiosInstance.get(`/quotations/${id}/purchase-order`);
  //       setPurchaseOrder(poResponse.data);
  //     } catch (err) {
  //       if (err.response && err.response.status !== 404) {
  //         console.error('Error fetching purchase order:', err);
  //       }
  //     }

  //     try {
  //       const soResponse = await axiosInstance.get(`/quotations/${id}/sales-order`);
  //       setSalesOrder(soResponse.data);
  //     } catch (err) {
  //       if (err.response && err.response.status !== 404) {
  //         console.error('Error fetching sales order:', err);
  //       }
  //     }

  //     try {
  //       const orderResponse = await axiosInstance.get(`/quotations/${id}/order`);
  //       setOrder(orderResponse.data);
  //     } catch (err) {
  //       if (err.response && err.response.status !== 404) {
  //         console.error('Error fetching order:', err);
  //       }
  //     }

  //     setIsLoading(false);
  //   } catch (err) {
  //     console.error('Error fetching quotation details:', err);
  //     setError('Failed to fetch quotation details. Please try again.');
  //     setIsLoading(false);
  //   }
  // };


  // const handleStatusChange = async (newStatus) => {
  //   try {
  //     await axiosInstance.put(`/quotations/${id}/status`, { status: newStatus });
  //     fetchQuotationDetails(); // Refetch all data to get updated information
  //   } catch (err) {
  //     console.error('Error updating quotation status:', err);
  //     setError('Failed to update quotation status. Please try again.');
  //   }
  // };

  const fetchQuotationDetails = async () => {
    try {
      const [quotationResponse, itemsResponse] = await Promise.all([
        axiosInstance.get(`/quotations/${id}`),
        axiosInstance.get(`/quotations/${id}/items`),
      ]);
      setQuotation(quotationResponse.data);
      setQuotationItems(itemsResponse.data);

      // Fetch additional data separately and handle potential 404s
      try {
        const poResponse = await axiosInstance.get(`/quotations/${id}/purchase-order`);
        setPurchaseOrder(poResponse.data);
      } catch (err) {
        if (err.response && err.response.status !== 404) {
          console.error('Error fetching purchase order:', err);
        }
      }

      try {
        const soResponse = await axiosInstance.get(`/quotations/${id}/sales-order`);
        setSalesOrder(soResponse.data);
      } catch (err) {
        if (err.response && err.response.status !== 404) {
          console.error('Error fetching sales order:', err);
        }
      }

      try {
        const orderResponse = await axiosInstance.get(`/quotations/${id}/order`);
        setOrder(orderResponse.data);
      } catch (err) {
        if (err.response && err.response.status !== 404) {
          console.error('Error fetching order:', err);
        }
      }

      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching quotation details:', err);
      setError('Failed to fetch quotation details. Please try again.');
      setSnackbar({ open: true, message: 'Failed to fetch quotation details. Please try again.', severity: 'error' });
      setIsLoading(false);
    }
  };

  // const handleStatusChange = async (newStatus) => {
  //   try {
  //     await axiosInstance.put(`/quotations/${id}/status`, { status: newStatus });
  //     console.log(`Quotation status updated to: ${newStatus}`);
  //     handleSnackbar('success', `Quotation status updated to ${newStatus}`);
      
  //     fetchQuotationDetails(); // Refetch all data to get updated information
  //   } catch (err) {
  //     console.error('Error updating quotation status:', err);
  //     // setError('Failed to update quotation status. Please try again.');
  //     handleSnackbar('error', `Failed to update quotation status: ${err.response?.data?.detail || err.message}`);
  //   }
  // };

  const handleStatusChange = async (newStatus) => {
    try {
      await axiosInstance.put(`/quotations/${id}/status`, { status: newStatus });
      console.log(`Quotation status updated to: ${newStatus}`);
      handleSnackbar('success', `Quotation status updated to ${newStatus}`);
      fetchQuotationDetails(); // Refetch all data to get updated information

      if (newStatus.toLowerCase() === 'accepted') {
        await fetchOrderDetails();
      }
      fetchQuotationDetails();
    } catch (err) {
      console.error('Error updating quotation status:', err);
      let errorMessage = 'Failed to update quotation status. Please try again.';
      if (err.response) {
        console.error('Error response:', err.response.data);
        if (err.response.data.detail) {
          if (typeof err.response.data.detail === 'string') {
            errorMessage = err.response.data.detail;
          } else if (Array.isArray(err.response.data.detail)) {
            errorMessage = err.response.data.detail.map(error => error.msg).join('; ');
          }
        }
      }
      handleSnackbar('error', errorMessage);
    }
  };

  
  const fetchOrderDetails = async () => {
    try {
      const orderResponse = await axiosInstance.get(`/quotations/${id}/order`);
      setOrder(orderResponse.data);
    } catch (err) {
      console.error('Error fetching order details:', err);
      handleSnackbar('error', 'Failed to fetch order details. Please try again.');
    }
  };

  const handlePoUpload = async () => {
    if (!poFile || !poNumber) {
      handleSnackbar('error', 'Please provide both PO number and file');
      return;
    }

    const formData = new FormData();
    formData.append('po_number', poNumber);
    formData.append('file', poFile);

    try {
      await axiosInstance.post(`/quotations/${id}/purchase-order`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      handleSnackbar('success', 'Purchase Order uploaded successfully');
      fetchQuotationDetails();
      setShowPoUpload(false);
    } catch (err) {
      console.error('Error uploading Purchase Order:', err);
      handleSnackbar('error', 'Failed to upload Purchase Order. Please try again.');
    }
  };

  const handleSoUpload = async () => {
    if (!soFile || !soNumber) {
      handleSnackbar('error', 'Please provide both SO number and file');
      return;
    }

    const formData = new FormData();
    formData.append('so_number', soNumber);
    formData.append('file', soFile);

    try {
      await axiosInstance.post(`/quotations/${id}/sales-order`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      handleSnackbar('success', 'Sales Order uploaded successfully');
      fetchQuotationDetails();
      setShowSoUpload(false);
    } catch (err) {
      console.error('Error uploading Sales Order:', err);
      handleSnackbar('error', 'Failed to upload Sales Order. Please try again.');
    }
  };

  const handleSnackbar = (severity, message) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // const handleSnackbarClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setSnackbar(prev => ({ ...prev, open: false }));
  // };

  // const handleSnackbar = (severity, message) => {
  //   setSnackbar({ open: true, message, severity });
  // };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  };

  const getStatusColor = (status) => {
    switch(status.toLowerCase()) {
      case 'sent': return '#3498db';
      case 'draft': return '#f39c12';
      case 'accepted': return '#2ecc71';
      case 'rejected': return '#e74c3c';
      default: return '#95a5a6';
    }
  };

  // if (isLoading) {
  //   return (
  //     <DashboardLayout>
  //       <DashboardNavbar />
  //       <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
  //         <CircularProgress />
  //       </MDBox>
  //     </DashboardLayout>
  //   );
  // }

  // if (error) {
  //   return (
  //     <DashboardLayout>
  //       <DashboardNavbar />
  //       <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
  //         <MDTypography variant="h4" color="error">{error}</MDTypography>
  //       </MDBox>
  //     </DashboardLayout>
  //   );
  // }

  // if (!quotation) {
  //   return (
  //     <DashboardLayout>
  //       <DashboardNavbar />
  //       <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
  //         <MDTypography variant="h4">Quotation not found</MDTypography>
  //       </MDBox>
  //     </DashboardLayout>
  //   );
  // }


  // if (isLoading) {
  //   return (
  //     <DashboardLayout>
  //       <DashboardNavbar />
  //       <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
  //         <CircularProgress />
  //       </MDBox>
  //     </DashboardLayout>
  //   );
  // }

  // if (error) {
  //   return (
  //     <DashboardLayout>
  //       <DashboardNavbar />
  //       <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
  //         <MDTypography variant="h4" color="error">{error}</MDTypography>
  //       </MDBox>
  //     </DashboardLayout>
  //   );
  // }

  // if (!quotation) {
  //   return (
  //     <DashboardLayout>
  //       <DashboardNavbar />
  //       <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
  //         <MDTypography variant="h4">Quotation not found</MDTypography>
  //       </MDBox>
  //     </DashboardLayout>
  //   );
  // }


  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </MDBox>
      </DashboardLayout>
    );
  }

  if (!quotation) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
          <MDTypography variant="h4">Quotation not found</MDTypography>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Quotation Details (ID: {id})
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDBox mb={3}>
                      <MDTypography variant="h6" fontWeight="medium">Quotation Info</MDTypography>
                      <TableContainer component={Paper}>
                        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <MDTypography variant="body2" fontWeight="medium">ID:</MDTypography>
                              </TableCell>
                              <TableCell>{quotation.id}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <MDTypography variant="body2" fontWeight="medium">Deal ID:</MDTypography>
                              </TableCell>
                              <TableCell>{quotation.deal_id}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <MDTypography variant="body2" fontWeight="medium">Quote Date:</MDTypography>
                              </TableCell>
                              <TableCell>{formatDate(quotation.quote_date)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <MDTypography variant="body2" fontWeight="medium">Total Amount:</MDTypography>
                              </TableCell>
                              <TableCell>{formatCurrency(quotation.total_amount)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <MDTypography variant="body2" fontWeight="medium">Account Name:</MDTypography>
                              </TableCell>
                              <TableCell>{quotation.account_name}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <MDTypography variant="body2" fontWeight="medium">Status:</MDTypography>
                              </TableCell>
                              <TableCell>
                                <Select
                                  value={quotation.status}
                                  onChange={(e) => handleStatusChange(e.target.value)}
                                  sx={{
                                    backgroundColor: getStatusColor(quotation.status),
                                    color: 'white',
                                    '& .MuiSelect-icon': { color: 'white' },
                                  }}
                                >
                                  <MenuItem value="draft">Draft</MenuItem>
                                  <MenuItem value="sent">Sent</MenuItem>
                                  <MenuItem value="accepted">Accepted</MenuItem>
                                  <MenuItem value="rejected">Rejected</MenuItem>
                                </Select>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <MDTypography variant="body2" fontWeight="medium">Created At:</MDTypography>
                              </TableCell>
                              <TableCell>{formatDate(quotation.created_at)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <MDTypography variant="body2" fontWeight="medium">Updated At:</MDTypography>
                              </TableCell>
                              <TableCell>{formatDate(quotation.updated_at)}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </MDBox>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium">Quotation Items</MDTypography>
                  <MDBox mt={2}>
                    <TableContainer component={Paper}>
                      <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Product ID</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Unit Price</TableCell>
                            <TableCell>Total Price</TableCell>
                            <TableCell>Discount %</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quotationItems.map((item) => (
                            <TableRow key={item.id} hover>
                              <TableCell>{item.product_id}</TableCell>
                              <TableCell>{item.quantity}</TableCell>
                              <TableCell>{formatCurrency(item.unit_price)}</TableCell>
                              <TableCell>{formatCurrency(item.total_price)}</TableCell>
                              <TableCell>{item.discount_percentage}%</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
  <Card>
    <MDBox
      mx={2}
      mt={-3}
      py={3}
      px={2}
      variant="gradient"
      bgColor="info"
      borderRadius="lg"
      coloredShadow="info"
    >
      <MDTypography variant="h6" color="white">
        Related Information
      </MDTypography>
    </MDBox>
    <MDBox p={3}>
      <MDBox mb={3}>
        <MDTypography variant="h6" fontWeight="medium">Purchase Order</MDTypography>
        {purchaseOrder ? (
          <>
            <MDTypography variant="body2">PO Number: {purchaseOrder.po_number}</MDTypography>
            <MDTypography variant="body2">Date: {formatDate(purchaseOrder.created_at)}</MDTypography>
            <MDButton 
              variant="outlined" 
              color="info" 
              size="small" 
              onClick={() => {/* Navigate to PO details */}}
            >
              View Details
            </MDButton>
          </>
        ) : (
          <>
            <MDButton 
              variant="outlined" 
              color="info" 
              size="small" 
              onClick={() => setShowPoUpload(!showPoUpload)}
            >
              Upload PO
            </MDButton>
            <Collapse in={showPoUpload}>
              <MDBox mt={2}>
                <TextField
                  label="PO Number"
                  value={poNumber}
                  onChange={(e) => setPoNumber(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Input
                  type="file"
                  onChange={(e) => setPoFile(e.target.files[0])}
                  fullWidth
                  margin="normal"
                />
                <MDButton 
                  variant="contained" 
                  color="info" 
                  size="small" 
                  onClick={handlePoUpload}
                >
                  Upload
                </MDButton>
              </MDBox>
            </Collapse>
          </>
        )}
      </MDBox>

      <MDBox mb={3}>
        <MDTypography variant="h6" fontWeight="medium">Sales Order</MDTypography>
        {salesOrder ? (
          <>
            <MDTypography variant="body2">SO Number: {salesOrder.so_number}</MDTypography>
            <MDTypography variant="body2">Date: {formatDate(salesOrder.created_at)}</MDTypography>
            <MDButton 
              variant="outlined" 
              color="info" 
              size="small" 
              onClick={() => {/* Navigate to SO details */}}
            >
              View Details
            </MDButton>
          </>
        ) : (
          <>
            <MDButton 
              variant="outlined" 
              color="info" 
              size="small" 
              onClick={() => setShowSoUpload(!showSoUpload)}
            >
              Upload SO
            </MDButton>
            <Collapse in={showSoUpload}>
              <MDBox mt={2}>
                <TextField
                  label="SO Number"
                  value={soNumber}
                  onChange={(e) => setSoNumber(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Input
                  type="file"
                  onChange={(e) => setSoFile(e.target.files[0])}
                  fullWidth
                  margin="normal"
                />
                <MDButton 
                  variant="contained" 
                  color="info" 
                  size="small" 
                  onClick={handleSoUpload}
                >
                  Upload
                </MDButton>
              </MDBox>
            </Collapse>
          </>
        )}
      </MDBox>

      {order && (
        <MDBox mb={3}>
          <MDTypography variant="h6" fontWeight="medium">Order</MDTypography>
          <MDTypography variant="body2">Order Number: {order.order_number}</MDTypography>
          <MDTypography variant="body2">Date: {formatDate(order.created_at)}</MDTypography>
          <MDButton 
            variant="outlined" 
            color="info" 
            size="small" 
            onClick={() => {/* Navigate to Order details */}}
          >
            View Details
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  </Card>
</Grid>
        </Grid>
      </MDBox>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

    </DashboardLayout>
  );
}

export default QuotationDetail;