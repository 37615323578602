import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Modal,
  Box,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import TableSkeleton from 'components/CustomSkeleton/TableSkeleton';

function Deals() {
  const { axiosInstance } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deals, setDeals] = useState([]);
  const [filters, setFilters] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [newDeal, setNewDeal] = useState({
    lead_id: '',
    name: '',
    value: '',
    stage: '',
    probability: '',
    closeDate: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchDeals();
  }, []);

  const fetchDeals = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/deals/`);
      setDeals(response.data);
    } catch (error) {
      console.error('Error fetching deals:', error);
      alert('Error fetching deals');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    setNewDeal({ ...newDeal, [e.target.name]: e.target.value });
  };

  const handleSaveDeal = async (event) => {
    event.preventDefault();
    console.log(newDeal);
    try {
      
      const token = localStorage.getItem('token');
      await axiosInstance.post(`/deals/`, newDeal);
      handleCloseModal();
      fetchDeals();
    } catch (error) {
      console.error('Error creating deal:', error);
    }
  };

  const handleRowClick = (rowData) => {
    navigate(`/deals/detail/${rowData.id}`);
  };


  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterApply = (column, value) => {
    setFilters({ ...filters, [column]: value });
    handleFilterClose();
  };

  const dealsTableData = {
    columns: [
      { 
        Header: "Name", 
        accessor: "name", 
        width: "25%",
        Cell: ({ row }) => (
          <MDTypography 
            component="span" 
            onClick={() => handleRowClick(row.original)}
            variant="caption" 
            color="text" 
            fontWeight="medium"
            sx={{ cursor: 'pointer' }}
          >
            {row.original.name}
          </MDTypography>
        )
      },
      { Header: "Value", accessor: "value", width: "15%" },
      { Header: "Stage", accessor: "stage", width: "20%" },
      { Header: "Probability", accessor: "probability", width: "15%" },
      { Header: "Create Date", accessor: "created_at", width: "15%" },
    ],
    rows: deals,
  };

  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        {/* Statistics Cards */}
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="attach_money"
                title="Total Deal Value"
                // count={`$${deals.reduce((sum, deal) => sum + parseInt(deal.value.replace('$', '').replace(',', '')), 0).toLocaleString()}`}
                count={`$${deals.reduce((sum, deal) => {
                  const value = typeof deal.value === 'string' 
                      ? deal.value.replace('$', '').replace(',', '') 
                      : deal.value;
                  return sum + parseInt(value, 10);
              }, 0).toLocaleString()}`}
                percentage={{
                  color: "success",
                  amount: "+15%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Win Rate"
                count="68%"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last quarter",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Deals Won"
                count={deals.filter(deal => deal.stage === "Closed Won").length}
                percentage={{
                  color: "success",
                  amount: "+1",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="New Deals"
                count={deals.filter(deal => new Date(deal.closeDate) > new Date()).length}
                percentage={{
                  color: "success",
                  amount: "+10%",
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <br/>
        <br/>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Deals
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {isLoading ? (
                  <TableSkeleton />
                ) : (
                  <DataTable
                    table={dealsTableData}
                    isSorted={true}
                    noEndBorder
                    canSearch
                    exportCSV={true}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      
      {/* Modal for adding new deal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            Add New Deal
          </MDTypography>
          <form onSubmit={handleSaveDeal}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField fullWidth label="Lead ID" name="lead_id" value={newDeal.lead_id} onChange={handleInputChange} variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Name" name="name" value={newDeal.name} onChange={handleInputChange} variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Value" name="value" value={newDeal.value} onChange={handleInputChange} variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Stage" name="stage" value={newDeal.stage} onChange={handleInputChange} variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Probability" name="probability" value={newDeal.probability} onChange={handleInputChange} variant="outlined" margin="normal" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Close Date" name="closeDate" type="date" value={newDeal.closeDate} onChange={handleInputChange} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
            <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
              <MDButton onClick={handleCloseModal} color="secondary" sx={{ marginRight: 2 }}>
                Close
              </MDButton>
              <MDButton type="submit" variant="gradient" color="info">
                Save Deal
              </MDButton>
            </MDBox>
          </form>
        </Box>
      </Modal>

      {/* Filter Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
      >
        <MenuItem onClick={() => handleFilterApply("stage", "")}>Clear All Filters</MenuItem>
        <MenuItem onClick={() => handleFilterApply("stage", "Proposal")}>Stage: Proposal</MenuItem>
        <MenuItem onClick={() => handleFilterApply("stage", "Negotiation")}>Stage: Negotiation</MenuItem>
        <MenuItem onClick={() => handleFilterApply("stage", "Closed Won")}>Stage: Closed Won</MenuItem>
      </Menu>

      <Footer />
    </DashboardLayout>
  );
}

export default Deals;