import React, { useState } from 'react';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Divider,
  TextField,
  Button,
  ThemeProvider,
  createTheme,
  Card,
  ListItemIcon,
} from '@mui/material';
import {
  Inbox as InboxIcon,
  Send as SendIcon,
  Delete as DeleteIcon,
  Drafts as DraftsIcon,
  Star as StarIcon,
  Mail as MailIcon,
  Reply as ReplyIcon,
  Forward as ForwardIcon,
  ReplyAll as ReplyAllIcon,
} from '@mui/icons-material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dummy data for emails
const dummyEmails = [
  { id: 1, from: 'john@example.com', subject: 'Meeting tomorrow', content: 'Hi, let\'s meet tomorrow at 2 PM.', read: false },
  { id: 2, from: 'sarah@example.com', subject: 'Project update', content: 'Here\'s the latest update on the project.', read: true },
  { id: 3, from: 'mike@example.com', subject: 'Lunch next week?', content: 'Would you like to grab lunch next week?', read: false },
];

// Create a custom theme
const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
  },
});

const mailboxes = [
  { name: 'Inbox', icon: <InboxIcon /> },
  { name: 'Sent', icon: <SendIcon /> },
  { name: 'Drafts', icon: <DraftsIcon /> },
  { name: 'Starred', icon: <StarIcon /> },
];

function EmailInbox() {
  const [emails, setEmails] = useState(dummyEmails);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [composing, setComposing] = useState(false);
  const [newEmail, setNewEmail] = useState({ to: '', subject: '', content: '' });
  const [currentMailbox, setCurrentMailbox] = useState('Inbox');

  const handleEmailSelect = (email) => {
    setSelectedEmail(email);
    setComposing(false);
  };

  const handleCompose = () => {
    setSelectedEmail(null);
    setComposing(true);
    setNewEmail({ to: '', subject: '', content: '' });
  };

  const handleSend = () => {
    console.log('Sending email:', newEmail);
    setComposing(false);
    setNewEmail({ to: '', subject: '', content: '' });
  };

  const handleReply = () => {
    setComposing(true);
    setNewEmail({
      to: selectedEmail.from,
      subject: `Re: ${selectedEmail.subject}`,
      content: `\n\nOn the previous email, ${selectedEmail.from} wrote:\n${selectedEmail.content}`,
    });
  };

  const handleReplyAll = () => {
    handleReply();
  };

  const handleForward = () => {
    setComposing(true);
    setNewEmail({
      to: '',
      subject: `Fwd: ${selectedEmail.subject}`,
      content: `\n\n-------- Forwarded Message --------\nFrom: ${selectedEmail.from}\nSubject: ${selectedEmail.subject}\n\n${selectedEmail.content}`,
    });
  };

  const handleMailboxSelect = (mailbox) => {
    setCurrentMailbox(mailbox);
    // Here you would typically fetch emails for the selected mailbox
    console.log(`Switched to ${mailbox}`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
      <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: 'white', boxShadow: 3 }}>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Inbox
                </MDTypography>
                </MDBox>
                <br />
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 200px)' }}>
            <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
              {/* Sidebar */}
              <Box sx={{ width: 200, flexShrink: 0, borderRight: 1, borderColor: 'divider' }}>
                <Button
                  variant="contained"
                  startIcon={<MailIcon />}
                  onClick={handleCompose}
                  sx={{ m: 2, width: 'calc(100% - 32px)' }}
                >
                  Compose
                </Button>
                <List>
                  {mailboxes.map((mailbox) => (
                    <ListItem 
                      button 
                      key={mailbox.name}
                      onClick={() => handleMailboxSelect(mailbox.name)}
                      sx={{
                        bgcolor: currentMailbox === mailbox.name ? 'action.selected' : 'inherit',
                        '&:hover': {
                          bgcolor: currentMailbox === mailbox.name ? 'action.selected' : 'action.hover',
                        },
                      }}
                    >
                      <ListItemIcon>{mailbox.icon}</ListItemIcon>
                      <ListItemText primary={mailbox.name} />
                    </ListItem>
                  ))}
                </List>
              </Box>
              
              {/* Email List */}
              <Box sx={{ width: 300, flexShrink: 0, borderRight: 1, borderColor: 'divider', overflow: 'auto' }}>
                <List>
                  {emails.map((email) => (
                    <ListItem 
                      button 
                      key={email.id} 
                      onClick={() => handleEmailSelect(email)}
                      sx={{ 
                        bgcolor: email === selectedEmail ? 'action.selected' : 'inherit',
                      }}
                    >
                      <ListItemText 
                        primary={
                          <Typography variant="body2" sx={{ fontWeight: email.read ? 'normal' : 'bold' }}>
                            {email.subject}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="body2" color="text.secondary">
                            {email.from}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
              
              {/* Email Content / Compose Email */}
              <Box sx={{ flexGrow: 1, p: 2, overflow: 'auto' }}>
                {composing ? (
                  <Box>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="To"
                      value={newEmail.to}
                      onChange={(e) => setNewEmail({ ...newEmail, to: e.target.value })}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Subject"
                      value={newEmail.subject}
                      onChange={(e) => setNewEmail({ ...newEmail, subject: e.target.value })}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Content"
                      multiline
                      rows={10}
                      value={newEmail.content}
                      onChange={(e) => setNewEmail({ ...newEmail, content: e.target.value })}
                    />
                    <Button
                      variant="contained"
                      startIcon={<SendIcon />}
                      onClick={handleSend}
                      sx={{ mt: 2 }}
                    >
                      Send
                    </Button>
                  </Box>
                ) : selectedEmail ? (
                  <Box>
                    <Typography variant="h6">{selectedEmail.subject}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      From: {selectedEmail.from}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1">{selectedEmail.content}</Typography>
                    <Box sx={{ mt: 2 }}>
                      <IconButton onClick={handleReply}><ReplyIcon /></IconButton>
                      <IconButton onClick={handleReplyAll}><ReplyAllIcon /></IconButton>
                      <IconButton onClick={handleForward}><ForwardIcon /></IconButton>
                      <IconButton><DeleteIcon /></IconButton>
                    </Box>
                  </Box>
                ) : (
                  <Typography variant="body1">Select an email to read</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
       
        </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmailInbox;