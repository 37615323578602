import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Modal,
  MenuItem,
  CircularProgress,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAuth } from 'auth/Auth';

const ConvertLeadModal = ({ isOpen, onClose, opportunityData, setOpportunityData, leadId }) => {
  const { user, axiosInstance } = useAuth();
  const [selectedContact, setSelectedContact] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);

  const [createNewContact, setCreateNewContact] = useState(true);
  const [createNewAccount, setCreateNewAccount] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchContacts();
    fetchAccounts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axiosInstance.get('/contacts');
      setContacts(response.data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await axiosInstance.get('/accounts');
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const handleOpportunityInputChange = (event) => {
    const { name, value } = event.target;
    setOpportunityData((prev) => ({ ...prev, [name]: value }));
  };

  const handleConvert = async () => {
    setIsLoading(true);
    try {
        let contactId = selectedContact?.id;
        let accountId = selectedAccount?.id;
      
        const leadData = await axiosInstance.get(`/leads/${leadId}`);
        console.log('leadData:', leadData.data);
        
        
        // if (createNewAccount) {
        //     const accountResponse = await axiosInstance.post('/accounts', {
        //       name: leadData.data.company_name,
        //       // account_id: accountId,
        //     });
        //     accountId = accountResponse.data.id;
        //   }

        if (createNewAccount) {

          const AccountData = {
            name: leadData.data.company_name,
            industry: leadData.data.industry,
            website: leadData.data.website,
            phone: leadData.data.phone,
            email: leadData.data.email,
            billing_address: leadData.data.address,
            billing_city: leadData.data.city,
            billing_state: leadData.data.state,
            billing_country: leadData.data.country,
            billing_postal_code: leadData.data.postal_code,
            shipping_address: leadData.data.address,
            shipping_city: leadData.data.city,
            shipping_state: leadData.data.state,
            shipping_country: leadData.data.country,
            shipping_postal_code: leadData.data.postal_code,
            currency: leadData.data.currency,
            annual_revenue: leadData.data.annual_revenue,
            number_of_employees: leadData.data.number_of_employees,
            account_status: 'Active',
            manager_id: leadData.data.user_id,
            account_type: leadData.data.lead_type || 'Customer',
            notes: leadData.data.notes,
            tags: leadData.data.tags || [],
          };

          console.log(`account Response:`, AccountData);
          
          const accountResponse = await axiosInstance.post('/accounts', AccountData);
          accountId = accountResponse.data.id;
        }



        // if (createNewContact) {
        //   const contactResponse = await axiosInstance.post('/contacts', {
        //     first_name: leadData.data.first_name,
        //     last_name: leadData.data.last_name,
        //     account_id: accountId,
        //   });
        //   console.log(`account_id: ${accountId}`);
          
        //   contactId = contactResponse.data.id;
        // }

        if (createNewContact) {
          const ContactData = 
            {
              first_name: leadData.data.first_name,
              last_name: leadData.data.last_name,
              account_id: accountId,
              email: leadData.data.email,
              phone: leadData.data.phone,
              title: leadData.data.title,
              mailing_address: leadData.data.address,
              mailing_city: leadData.data.city,
              mailing_state: leadData.data.state,
              mailing_country: leadData.data.country,
              mailing_postal_code: leadData.data.postal_code,
              department: leadData.data.industry,
              contact_status: 'Active',
              manager_id: leadData.data.user_id,
              currency: leadData.data.currency,
              notes: leadData.data.notes,
              contact_role: leadData.data.title,
              mobile_phone: leadData.data.phone,
              tags: leadData.data.tags || [],
            };

            console.log(`contactResponse:`,ContactData);
          
          const contactResponse = await axiosInstance.post('/contacts', ContactData);
          contactId = contactResponse.data.id;

          console.log(`contact_id: ${contactId}`);

          
        }
      
        // const convertData = {
        //   ...opportunityData,
        //   contact_id: contactId,
        //   account_id: accountId,
        //   value: parseFloat(opportunityData.value),
        //   probability: parseFloat(opportunityData.probability) / 100,
        //   lead_id: leadId,
        // };

        const convertData = {
          name: opportunityData.name || `${leadData.data.company_name} Opportunity`,
          contact_id: contactId,
          account_id: accountId,
          value: parseFloat(opportunityData.value),
          currency: opportunityData.currency || leadData.data.currency || 'USD',
          stage: opportunityData.stage || 'Qualification',
          probability: parseFloat(opportunityData.probability) / 100,
          close_date: opportunityData.close_date || new Date().toISOString().split('T')[0],
          user_id: leadData.data.user_id,
          lead_id: leadId,
          deal_type: opportunityData.deal_type || 'New Business',
          description: opportunityData.description || leadData.data.notes,
          expected_revenue: parseFloat(opportunityData.value) * (parseFloat(opportunityData.probability) / 100),
          campaign_source: leadData.data.lead_source,
          next_step: opportunityData.next_step || 'Initial Contact',
          compititor: opportunityData.compititor || leadData.data.compititor || 'Not set',
        };
      
        console.log('Convert data:', convertData);
      
        const response = await axiosInstance.post(`/leads/${leadId}/convert`, convertData);
        console.log('Lead converted to deal:', response.data);
        navigate('/deals');
      
      } catch (error) {
      console.error('Error during conversion:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="convert-modal-title"
      aria-describedby="convert-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflowY: 'auto',
      }}>
        <MDTypography id="convert-modal-title" variant="h6" component="h2" mb={2}>
          Convert Lead to Deal
        </MDTypography>
        <TextField
          fullWidth
          label="Deal Name"
          name="name"
          value={opportunityData.name}
          onChange={handleOpportunityInputChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Value"
          name="value"
          type="number"
          value={opportunityData.value}
          onChange={handleOpportunityInputChange}
          margin="normal"
          required
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="stage-label">Stage</InputLabel>
          <Select
            labelId="stage-label"
            id="stage"
            name="stage"
            value={opportunityData.stage}
            label="Stage"
            onChange={handleOpportunityInputChange}
            inputProps={{ IconComponent: () => <ArrowDropDownIcon style={{ scale: '1.5' }} /> }}
            style={{
              padding: '11px',
            }}
          >
            <MenuItem value="Qualification">Qualification</MenuItem>
            <MenuItem value="Proposal">Proposal</MenuItem>
            <MenuItem value="Negotiation">Negotiation</MenuItem>
            <MenuItem value="Closed Won">Closed Won</MenuItem>
            <MenuItem value="Closed Lost">Closed Lost</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Probability (%)"
          name="probability"
          type="number"
          value={opportunityData.probability}
          onChange={handleOpportunityInputChange}
          margin="normal"
          InputProps={{ inputProps: { min: 0, max: 100 } }}
        />
        <TextField
          fullWidth
          label="Close Date"
          name="close_date"
          type="date"
          value={opportunityData.close_date}
          onChange={handleOpportunityInputChange}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        
       {/* Contact Creation/Selection */}
       <FormControlLabel
          control={
            <Checkbox
              checked={createNewContact}
              onChange={(e) => setCreateNewContact(e.target.checked)}
            />
          }
          label="Create New Contact"
        />
        {!createNewContact && (
          <Autocomplete
            fullWidth
            options={contacts}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Search existing contacts" />}
            value={selectedContact}
            onChange={(event, newValue) => setSelectedContact(newValue)}
            margin="normal"
          />
        )}

        {/* Account Creation/Selection */}
        <FormControlLabel
          control={
            <Checkbox
              checked={createNewAccount}
              onChange={(e) => setCreateNewAccount(e.target.checked)}
            />
          }
          label="Create New Account"
        />
        {!createNewAccount && (
          <Autocomplete
            fullWidth
            options={accounts}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Search existing accounts" />}
            value={selectedAccount}
            onChange={(event, newValue) => setSelectedAccount(newValue)}
            margin="normal"
          />
        )}

        <MDBox display="flex" justifyContent="flex-end" mt={3}>
        {!isLoading && (
            <MDButton onClick={onClose} color="secondary" sx={{ marginRight: 2 }}>
                Cancel
            </MDButton>
        )}
          <MDButton onClick={handleConvert} variant="contained" color="success" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Convert'}
          </MDButton>
        </MDBox>
      </Box>
    </Modal>
  );
};

export default ConvertLeadModal;