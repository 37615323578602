/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import { useAuth } from "../../auth/Auth";
import DarkModeToggle from "../../components/CustomDarkMode";
// @mui material components
import Grid from "@mui/material/Grid";
import { Fade, Box } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import EmailActivity from "layouts/dashboard/components/EmailActivity";
import DailyPlan from "layouts/dashboard/components/DailyPlan";
function Dashboard() {
  const { user, axiosInstance } = useAuth();
  const { sales, tasks } = reportsLineChartData;
  const [greeting, setGreeting] = useState("");
  const [visible, setVisible] = useState(false);
  const userName = user?.username || 'User';

  const [totalLeads, setTotalLeads] = useState(0);

  useEffect(() => {
    const hour = new Date().getHours();
    let greet;
    if (hour < 12) greet = "Good morning";
    else if (hour < 18) greet = "Good afternoon";
    else greet = "Good evening";
    setGreeting(greet);
    setVisible(true);
  }, []);

  useEffect(() => {
    const fetchTotalLeads = async () => {
      try {
        const response = await axiosInstance.get(`/leads/count`);
        setTotalLeads(response.data.count);
      } catch (error) {
        console.error("Error fetching total leads:", error);
      }
    };
    fetchTotalLeads();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        
        <Fade in={visible} timeout={1000}>
          <Box
            sx={{
              background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
              borderRadius: '15px',
              padding: '1.5rem',
              marginBottom: '1.5rem',
              boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            }}
          >
            <MDTypography variant="h3" fontWeight="bold" color="white" textTransform="capitalize">
              {greeting}, {userName}!
            </MDTypography>
            <MDTypography variant="h5" fontWeight="regular" color="white" mt={1}>
              Welcome to your Dashboard 
            </MDTypography>
          </Box>
        </Fade>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="group"
                title="Total Leads"
                count={totalLeads}
                percentage={{
                  color: "success",
                  amount: "+5%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="New Leads"
                count={totalLeads}
                percentage={{
                  color: "success",
                  amount: "+12%",
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Sales"
                count="$34k"
                percentage={{
                  color: "success",
                  amount: "+8%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Conversion Rate"
                count="3.5%"
                percentage={{
                  color: "success",
                  amount: "+12%",
                  label: "than last quarter",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Lead Sources"
                  description="Performance by lead source"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Monthly Sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in monthly sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Customer Retention"
                  description="Customer retention rate over time"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <EmailActivity />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <DailyPlan />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;