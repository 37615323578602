import React, { useState } from 'react';
import {
  Modal,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import MDTypography from 'components/MDTypography';
import CustomInputForm from 'components/CustomInputForm';
import { useAuth } from 'auth/Auth';

const AddContactModal = ({ isOpen, onClose, accountId, onContactAdded }) => {
  const { axiosInstance } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fields = [
    { name: 'first_name', label: 'First Name', type: 'text', required: true },
    { name: 'last_name', label: 'Last Name', type: 'text', required: true },
    { name: 'email', label: 'Email', type: 'text', inputType: 'email', required: true },
    { name: 'phone', label: 'Phone', type: 'text', inputType: 'tel' },
  ];

  const handleSubmit = async (data) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post('/contacts/', {
        ...data,
        account_id: accountId, // Assuming you want to associate the contact with an account
      });
      setSuccessSnackbar(true);
      onClose();
      await onContactAdded();
    } catch (err) {
        setErrorMessage('Failed to create contact. Please try again.');
        setErrorSnackbar(true);
        console.error('Error creating contact:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <>
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="contact-modal-title"
      aria-describedby="contact-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        <MDTypography id="contact-modal-title" variant="h6" component="h2" mb={2}>
          Add Contact
        </MDTypography>

        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <CustomInputForm
            fields={fields}
            onSubmit={handleSubmit}
            onCancel={onClose}
          />
        )}
      </Box>
    </Modal>

        <Snackbar 
            open={successSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSuccessSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
        <Alert onClose={handleCloseSuccessSnackbar} severity="success" sx={{ width: '100%' }}>
          Contact created successfully!
        </Alert>
      </Snackbar>

      <Snackbar 
        open={errorSnackbar} 
        autoHideDuration={6000} 
        onClose={handleCloseErrorSnackbar} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
        <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

    </>
  );
};

export default AddContactModal;