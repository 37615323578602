// components/DarkModeToggle.js
import { useMaterialUIController, setDarkMode } from "context";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DarkModeToggle() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;

  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  return (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="body2" color="text">
        Light
      </MDTypography>
      <Switch checked={darkMode} onChange={handleDarkMode} />
      <MDTypography variant="body2" color="text">
        Dark
      </MDTypography>
    </MDBox>
  );
}

export default DarkModeToggle;