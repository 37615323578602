import React, { createContext, useState, useContext } from 'react';

const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    generalSettings: {
      companyName: '',
      timezone: '',
      language: '',
    },
    userSettings: {
      allowUserRegistration: false,
      defaultUserRole: '',
    },
    emailSettings: {
      smtpServer: '',
      smtpPort: '',
      smtpUsername: '',
      smtpPassword: '',
    },
    aiSettings: {
      enableAI: false,
      aiModel: '',
    },
    personalInfo: {
      name: '',
      email: '',
      phone: '',
    },
    automationSettings: {
      enableAutomations: false,
      automationFrequency: '',
    },
  });

  const updateSettings = (newSettings) => {
    setSettings(prevSettings => ({ ...prevSettings, ...newSettings }));
  };

  return (
    <SettingsContext.Provider value={{ settings, updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
