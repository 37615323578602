import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Chip,
  OutlinedInput,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ProductSelectionTable from 'components/ProductSelectionTable';
import { useAuth } from '../../auth/Auth';

// const demoProducts = [
//     { id: 471, name: "Laptop", price: 999.99, description: "High-performance laptop" },
//     { id: 475, name: "Smartphone", price: 699.99, description: "Latest model smartphone" },
//     { id: 472, name: "Tablet", price: 299.99, description: "Lightweight tablet" },
//     { id: 473, name: "Desktop Computer", price: 1299.99, description: "Powerful desktop setup" },
//     { id: 476, name: "Wireless Headphones", price: 199.99, description: "Noise-cancelling headphones" },
//   ];

function QuotationModal({ isOpen, onClose, onSave }) {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [quotationStatus, setQuotationStatus] = useState('draft');
  const [totalAmount, setTotalAmount] = useState(0);
  const [allProducts, setAllProducts] = useState([]);

  const [tax, setTax] = useState(0);
  const [shippingMethod, setShippingMethod] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [notes, setNotes] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');

  const { user, axiosInstance } = useAuth();

  const handleProductsChange = (updatedProducts) => {
    setSelectedProducts(updatedProducts);
  };

  // const calculateTotalAmount = () => {
  //   return selectedProducts.reduce((total, product) => {
  //     const productTotal = product.price * product.quantity;
  //     const discountAmount = productTotal * (product.discount / 100);
  //     return total + (productTotal - discountAmount);
  //   }, 0);
  // };

  const calculateTotalAmount = () => {
    const subtotal = selectedProducts.reduce((total, product) => {
      const productTotal = product.price * product.quantity;
      const discountAmount = productTotal * (product.discount / 100);
      return total + (productTotal - discountAmount);
    }, 0);
    const taxAmount = subtotal * (tax / 100);
    return subtotal + taxAmount;
  };

  // useEffect(() => {
  //   const newTotalAmount = calculateTotalAmount();
  //   setTotalAmount(newTotalAmount);
  // }, [selectedProducts]);

  useEffect(() => {
    const newTotalAmount = calculateTotalAmount();
    setTotalAmount(newTotalAmount);
  }, [selectedProducts, tax]);

  useEffect(() => {
      fetchProducts();
  }, []);

  const fetchProducts = () => {
    axiosInstance
      .get('/inventory/details/')
      .then(response => {
        const transformedProducts = response.data.map(product => ({
          id: product.product_id,
          name: product.product_name,
          price: product.price,
          description: `${product.category} - ${product.product_code}`,
        }));
        setAllProducts(transformedProducts);
        console.log(`Transformed Products: ${JSON.stringify(transformedProducts)}`);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }
  // const handleSaveQuotation = () => {
  //   onSave({
  //     status: quotationStatus,
  //     total_amount: totalAmount,
  //     items: selectedProducts.map(product => ({
  //       product_id: product.id,
  //       quantity: product.quantity,
  //       unit_price: parseFloat(product.price),
  //       total_price: parseFloat((product.price * product.quantity).toFixed(2)),
  //       discount_percentage: product.discount ? parseFloat(product.discount) : null,
  //     })),
  //   });
  //   onClose();
  // };

  const handleSaveQuotation = () => {
    onSave({
      status: quotationStatus,
      total_amount: totalAmount,
      items: selectedProducts.map(product => ({
        product_id: product.id,
        quantity: product.quantity,
        unit_price: parseFloat(product.price),
        total_price: parseFloat((product.price * product.quantity).toFixed(2)),
        discount_percentage: product.discount ? parseFloat(product.discount) : null,
      })),
      tax: parseFloat(tax),
      shipping_method: shippingMethod,
      payment_methods: paymentMethods,
      notes: notes,
      delivery_date: (new Date(deliveryDate)).toISOString().split('T')[0] || null,
    });
    onClose();

    console.log('Quotation Items:', {
      status: quotationStatus,
      total_amount: totalAmount,
      items: selectedProducts.map(product => ({
        product_id: product.id,
        quantity: product.quantity,
        unit_price: parseFloat(product.price),
        total_price: parseFloat((product.price * product.quantity).toFixed(2)),
        discount_percentage: product.discount ? parseFloat(product.discount) : null,
      })),
      tax: parseFloat(tax),
      shipping_method: shippingMethod,
      payment_methods: paymentMethods,
      notes: notes,
      delivery_date: (new Date(deliveryDate)).toISOString().split('T')[0] || null,
    });
    
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="quotation-modal-title"
      aria-describedby="quotation-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: 800,
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
      }}>
        <MDTypography id="quotation-modal-title" variant="h6" component="h2" mb={2}>
          Add New Quotation
        </MDTypography>

        <ProductSelectionTable 
          products={allProducts} 
          onProductsChange={handleProductsChange}
        />

        <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox>
            <MDTypography variant="subtitle2" mr={2}>
              Status:
            </MDTypography>
            <Select
              value={quotationStatus}
              onChange={(e) => setQuotationStatus(e.target.value)}
              size="medium"
              sx={{
                width: '60px',
                height: '30px',
              }}
            >
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="sent">Sent</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </MDBox>
          <MDTypography variant="h6">
            Total Amount: ${totalAmount.toFixed(2)}
          </MDTypography>
        </MDBox>

        <MDBox mt={2}>
          <TextField
            label="Tax (%)"
            type="number"
            value={tax}
            onChange={(e) => setTax(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Shipping Method"
            value={shippingMethod}
            onChange={(e) => setShippingMethod(e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="payment-methods-label">Payment Methods</InputLabel>
            <Select
              labelId="payment-methods-label"
              multiple
              value={paymentMethods}
              onChange={(e) => setPaymentMethods(e.target.value)}
              input={<OutlinedInput id="select-multiple-chip" label="Payment Methods" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              sx={{
                height: '50px',
              }}
            >
              <MenuItem value="Credit Card">Credit Card</MenuItem>
              <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
              <MenuItem value="PayPal">PayPal</MenuItem>
              <MenuItem value="Cash">Cash</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Delivery Date"
            type="date"
            value={deliveryDate}
            onChange={(e) => setDeliveryDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            label="Notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        </MDBox>

        <MDBox mt={4} display="flex" justifyContent="flex-end">
          <MDButton onClick={onClose} color="secondary" sx={{ mr: 2 }}>
            Cancel
          </MDButton>
          <MDButton onClick={handleSaveQuotation} variant="gradient" color="info">
            Save Quotation
          </MDButton>
        </MDBox>
      </Box>
    </Modal>
  );
}

export default QuotationModal;


//   return (
//     <Modal
//       open={isOpen}
//       onClose={onClose}
//       aria-labelledby="quotation-modal-title"
//       aria-describedby="quotation-modal-description"
//     >
//       <Box sx={{
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: '80%',
//         maxWidth: 800,
//         maxHeight: '90vh',
//         bgcolor: 'background.paper',
//         boxShadow: 24,
//         p: 4,
//         overflow: 'auto',
//       }}>
//         <MDTypography id="quotation-modal-title" variant="h6" component="h2" mb={2}>
//           Add New Quotation
//         </MDTypography>

//         <ProductSelectionTable 
//           products={allProducts} 
//           onProductsChange={handleProductsChange}
//         />

//         <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
//           <MDBox>
//             <MDTypography variant="subtitle2" mr={2}>
//               Status:
//             </MDTypography>
//             <Select
//               value={quotationStatus}
//               onChange={(e) => setQuotationStatus(e.target.value)}
//               size="small"
//             >
//               <MenuItem value="draft">Draft</MenuItem>
//               <MenuItem value="sent">Sent</MenuItem>
//               <MenuItem value="accepted">Accepted</MenuItem>
//               <MenuItem value="rejected">Rejected</MenuItem>
//             </Select>
//           </MDBox>
//           <MDTypography variant="h6">
//             Total Amount: ${totalAmount.toFixed(2)}
//           </MDTypography>
//         </MDBox>

//         <MDBox mt={4} display="flex" justifyContent="flex-end">
//           <MDButton onClick={onClose} color="secondary" sx={{ mr: 2 }}>
//             Cancel
//           </MDButton>
//           <MDButton onClick={handleSaveQuotation} variant="gradient" color="info">
//             Save Quotation
//           </MDButton>
//         </MDBox>
//       </Box>
//     </Modal>
//   );
// }

// export default QuotationModal;